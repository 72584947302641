import { computed, getCurrentInstance } from 'vue'

export function useRoute() {
  const instance = getCurrentInstance()
  if (!instance) {
    throw new Error(`Should be used in setup().`)
  }
  // reactiveにするため
  return {
    route: computed(() => instance.proxy.$route),
    router: instance.proxy.$router,
  };
}
