<template>
<header class="contents_wrap">
  <div class="head_l"><a href="https://awesome-wash.com"><img src="/static/images/logo_awesomewash.svg" :alt="$t('header.logoAlt')"></a></div>
  <div class="localize_button">
    <LocalizationSwitcher />
  </div>
  <div class="head_r tab_none sp_none">
    <a href="https://awesome-wash.com" target="_blank" class="btn_home link_head radius4">
      <p>{{ $t('awashHome') }}</p>
    </a>
  </div>
</header>
</template>

<script>
import LocalizationSwitcher from '@/components/lib/LocalizationSwitcher.vue'

export default {
  name: 'no-login-header',
  components: {
    LocalizationSwitcher,
  }
}
</script>
