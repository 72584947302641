<template>
<ul class="list">
  <li v-if="isTypeA"><p>{{ $t('notice.washRequest.additionalPurchase') }}</p></li>
  <li><p>{{ $t('notice.washRequest.deliveryBoxPassword') }}</p></li>
  <li><p>{{ $t('notice.washRequest.confirmationEmail') }}</p></li>
  <li><p>{{ $t('notice.washRequest.changeTimeLimit') }}</p></li>
  <li><p>{{ $t('notice.washRequest.onlyDelayAllowed') }}</p></li>
  <li><p>{{ $t('notice.washRequest.minDeliveryTime') }}</p></li>
  <li><p>{{ $t('notice.washRequest.sameDayLimit') }}</p></li>
  <li><p>{{ $t('notice.washRequest.timeWindow') }}</p></li>
  <li><p>{{ $t('notice.washRequest.absenceReschedule') }}</p></li>
  <li><p>{{ $t('notice.washRequest.autoCancel') }}</p></li>
  <li><p>{{ $t('notice.washRequest.autoAdditionalPurchase') }}</p></li>
</ul>
</template>

<script>
export default {
  name: 'wash-request-notice-messages',
  props: {
    mongonType: {
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {
    isTypeA() {
      return this.mongonType === 'A'
    }
    ,isTypeB() {
      return this.mongonType === 'B'
    }
  },
}
</script>