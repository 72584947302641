<template>
  <modal
    v-if="true"
    :showTopDismissButton="true"
    :showFooter="false"
    @dismiss="dismiss"
  >
    <div slot="body">
      <div class="modal-content pt10 pb10">
        <p class="font_blue">・{{ $t('sameTimeDeliveryNote.separate.title') }}</p>
        <p>{{ $t('sameTimeDeliveryNote.separate.description') }}</p>
        <p class="font_blue"><br>・{{ $t('sameTimeDeliveryNote.sameTime.title') }}</p>
        <p v-html="$t('sameTimeDeliveryNote.sameTime.description')"></p>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    dismiss() {
      this.$emit('dismiss')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  font-size: 1.0rem;
}
</style>
