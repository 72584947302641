<template>
  <div class="contents_855">
    <div class="contents_box">
      <p class="mg_bt30 font_orange">{{ $t('register.requiredFieldNote') }}</p>
    </div>

    <h2 class="title">{{ $t('register.applicationDetails') }}</h2>
    <InputFormPlanParts />

    <h2 class="title mg40">{{ $t('register.customerInformation') }}</h2>
    <InputFormIndividual
      :show-contract-type="false"
      :show-password="true"
      :show-payment-method="!commonState.isOnetime"
      @on-contract-mode-change="onContractModeChange"
    />

    <div class="form_notes">
      <p class="font_big font_orange" v-html="$t('register.termsConfirmation')"></p><br>
      <p class="center"><input type="checkbox" id="agreement" v-model="commonState.agreeToTerms"><label for="agreement" class="agreement-label">{{ $t('register.agreeToTerms') }}</label></p><br>
      <p class="tab_br_none sp_br_none mg_bt30" v-html="$t('register.emailSettingsNote')"></p>
    </div>

    <hr class="hr_dot_gray">
    <div class="err-msg-area" v-show="hasError">
      <span class="font_orange">
        {{ $t('hasErrors') }}
      </span>
    </div>
    <p class="submit_bx1">
      <button @click="toConfirmStep()" class="submit1 bg_green step1do" :disabled="!commonState.agreeToTerms">
        {{ $t('confirmButton') }}
      </button>
    </p>
  </div>
</template>

<script>
import { computed, defineComponent, getCurrentInstance } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useUserErrorState } from '@/components/lib/user/composables/useUserErrorState'
import { useCommonState } from '@/components/Register/composables/useCommonState'
import InputFormPlanParts from '@/components/RegisterViaPartnerVenue/components/InputFormPlanParts.vue'
import InputFormIndividual from '@/components/lib/user/components/InputFormIndividual.vue'
import { validateUserParams } from '@/validations/userValidations'
import { convertUserDataForSave } from '@/utils/userUtils'
import { validateUserPaymentMethod } from '@/validations/userPaymentMethodValidations'

export default defineComponent({
  components: { InputFormIndividual, InputFormPlanParts },
  setup() {
    const vue = getCurrentInstance().proxy
    const commonState = useCommonState()
    const { userState } = useUserState()
    const { userErrorState } = useUserErrorState()

    const hasError = computed(() => {
      return Object.keys(commonState.errors).length > 0 || Object.keys(userErrorState.errors).length > 0
    })

    function onContractModeChange(eventObj) {
      userState.is_company = eventObj.isCompany
      // 支払い方法だけはデフォルトに戻す
      userState.payment_method_id = '1'
    }

    function validateInputStep() {
      const state = commonState

      state.errors = {}
      userErrorState.errors = {}
      let result = true

      if (!state.planPart2) {
        state.errors['planPart2'] = true
        state.errors['planPart2_required'] = true
        result = false
      }

      if (!userState.send_addr_detail3_room_number) {
        userErrorState.errors['send_addr_detail3_room_number'] = true
        userErrorState.errors['send_addr_detail3_room_number_required'] = true
        result = false
      }

      const [isUserParamsOk, userParamErrors] = validateUserParams(userState, state.planId, state.areaInfos)
      if (!isUserParamsOk) {
        userErrorState.errors = { ...userErrorState.errors, ...userParamErrors }
        result = false
      }

      const [isUserPaymentMethodOk, userPaymentMethodErrors] = validateUserPaymentMethod(userState, state.planId, state.bagSize)
      if (!isUserPaymentMethodOk) {
        userErrorState.errors = { ...userErrorState.errors, ...userPaymentMethodErrors }
        result = false
      }

      return result
    }

    function toConfirmStep() {
      convertUserDataForSave(userState)

      if (!validateInputStep()) { return }
      vue.$emit('to-confirm-step')
    }

    return {
      commonState,
      // computed
      hasError,
      // methods
      onContractModeChange,
      toConfirmStep,
    }
  }
})
</script>

<style lang="scss" scoped>
.step1do:disabled {
  opacity: 0.6;
}
.disabled {
  filter: alpha(opacity=5);
  -moz-opacity:0.5;
  opacity:0.5;
}

.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.submit_bx1 {
  margin: 12px auto;
}
</style>
