<template>
  <div>
    <no-login-header />
    <h1 class="pagetitle">{{ $t('register.title') }}</h1>
    <InputStepView
      v-if="isInputStep"
      @to-confirm-step="toConfirmStep"
    />
    <ConfirmStepView
      v-else-if="isConfirmStep"
      @back-to-input-step="backToInputStep"
    />
    <no-login-footer />

    <SameTimeDeliveryNoteModal
      v-if="state.showSameTimeDeliveryNoteModal"
      @dismiss="state.showSameTimeDeliveryNoteModal = false"
    />
  </div>
</template>

<script>

import { defineComponent, onMounted, computed } from 'vue'
import areaInfoApi from '@/api/areaInfo'
import venueApi from '@/api/venue'
import { provideMaster } from '@/composables/useMaster'
import { provideUserState } from '@/components/lib/user/composables/useUserState'
import { provideUserErrorState } from '@/components/lib/user/composables/useUserErrorState'
import { provideCommonState } from '@/components/Register/composables/useCommonState'
import InputStepView from '@/components/RegisterViaPartnerVenue/components/InputStepView.vue'
import ConfirmStepView from '@/components/Register/components/ConfirmStepView.vue'
import SameTimeDeliveryNoteModal from '@/components/lib/SameTimeDeliveryNoteModal.vue'
import i18n from '@/utils/i18n'
import { useRoute } from '@/hook/useRoute'
import { useStore } from '@/hook/useStore'

export default defineComponent({
  name: 'register-via-partner-venue',
  setup() {
    // TODO プランに関係するものはusePlanStateを作って切り出したい
    const state = provideCommonState()
    const userState = provideUserState()
    provideUserErrorState()
    const { route } = useRoute()
    const store = useStore()

    const { _masterPromise: masterPromise } = provideMaster()

    const isInputStep = computed(() => {
      return state.currentStep === 1
    })
    const isConfirmStep = computed(() => {
      return state.currentStep === 2
    })

    function toConfirmStep() {
      state.currentStep = 2
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }
    function backToInputStep() {
      state.currentStep = 1
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }

    onMounted(async () => {
      try {
        // URLから取得したvenueCodeを元にvenueを取得
        // venue_codeに該当するvenueが取得できなかった場合、通常の申し込み画面にリダイレクトする
        const venueCode = route.value.params.venueCode
        const { data: venue } = await venueApi.getVenue(venueCode)
        userState.venue = venue
        userState.source_venue_code = venueCode
      } catch (e) {
        window.location.href = '/register'
        return
      }
      const config = JSON.parse(localStorage.getItem('awash_web')) || {}
      if (!config.localization) {
        const defaultLang = userState.venue.default_lang
        await store.dispatch('localStorage/set', {
          key: 'localization',
          val: defaultLang,
        })
        i18n.locale = defaultLang
        window.location.reload()
      }

      // TODO commonStateからmasterData周りのものを剥がしていきたい
      const [masterData, areaInfoResponse] = await Promise.all([
        masterPromise,
        areaInfoApi.index(),
      ])

      state.lovs = masterData.lovs
      state.plans = masterData.plans
      state.planMap = masterData.planMap
      state.lovsMap = masterData.lovsMap
      state.other = masterData.other

      if (state.lovs.plan_part) {
        state.planParts1 = state.lovs.plan_part.filter(p => {
          // 単発のみ選択可能
          return p['key'] === 'tx'
        })
        state.planPart1 = state.planParts1[0].key
      }

      state.areaInfos = areaInfoResponse.data

      // パートナー物件の住所を集配先住所に設定
      userState.send_addr_zipcode = userState.venue.send_addr_zipcode
      userState.send_addr_detail1 = userState.venue.send_addr_detail1
      userState.send_addr_detail2 = userState.venue.send_addr_detail2
      userState.send_addr_detail3 = userState.venue.send_addr_detail3
    })

    return {
      state,
      // computed
      isInputStep,
      isConfirmStep,
      // methods
      toConfirmStep,
      backToInputStep,
    }
  },
  components: {
    InputStepView,
    ConfirmStepView,
    SameTimeDeliveryNoteModal,
  },
})
</script>

<style lang="scss" scoped>
</style>
