<template>
  <div>
    <h2 class="title">{{ $t('profile.editTitle') }}</h2>
    <p class="mypage_notes mg30 mg_bt30 font_orange">
      {{ $t('profile.confirmInfo') }}
    </p>

    <ReadonlyViewIndividual
      v-if="!isCompany"
      :show-payment-method="false"
    />
    <ReadonlyViewCompany
      v-else
      :show-payment-method="false"
    />

    <div class="err-msg-area">
      <p><span class="font_orange" v-for="e in serverErrors">
        {{e}}
      </span></p>
    </div>

    <p class="submit_bx_l">
      <button @click="goBack" class="submit1 bg_gray">
        {{ $t('backButton') }}
      </button>
    </p>
    <p class="submit_bx_r">
      <button @click="goNext" class="submit1 bg_green">
        {{ $t('okButton') }}
      </button>
    </p>
  </div>
</template>

<script>
import { computed, defineComponent, getCurrentInstance, reactive, ref } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import userApi from '@/api/user'
import ReadonlyViewIndividual from '@/components/lib/user/components/ReadonlyViewIndividual.vue'
import ReadonlyViewCompany from '@/components/lib/user/components/ReadonlyViewCompany.vue'

export default defineComponent({
  components: { ReadonlyViewCompany, ReadonlyViewIndividual },
  setup() {
    const vue = getCurrentInstance().proxy
    const { userState, forceRefresh: forceUserStateRefresh } = useUserState()
    const serverErrors = ref([])

    const isCompany = computed(() => {
      return userState.is_company
    })

    function goBack() {
      vue.$emit('back')
    }

    async function updateProfile() {
      serverErrors.value = []

      try {
        await userApi.updateMe(userState)
      } catch (e) {
        if (e.response.data.reason === 'status_withdrawn') {
          serverErrors.value.push(this.$t('accountWithdrawn'))
          await vue.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: null,
          })
          return false
        }

        const errRes = e.response.data.errors
        let errors = []
        Object.keys(errRes).forEach(k => {
          errors = errors.concat(errRes[k])
        })
        serverErrors.value = errors

        return false
      }

      await forceUserStateRefresh()
      return true
    }

    async function goNext() {
      if (!await updateProfile()) { return }
      vue.$emit('next')
    }

    return {
      userState,
      serverErrors,
      // computed
      isCompany,
      // methods
      goBack,
      goNext,
    }
  }
})
</script>

<style lang="scss" scoped>
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
</style>
