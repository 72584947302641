<template>
  <div class="contents_box">
    <table class="form_tab">
      <tr>
        <th><p class="item2">{{ $t('register.plan.title') }}<span class="font_orange">*</span></p></th>
        <td>
          <p>
            <span>{{ $t('master.planPart.tx') }}</span>
          </p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('plan.pickupMethod') }}<span class="font_orange">*</span></p></th>
        <td>
          <p>
            <select v-model="commonState.planPart2" class="w250" @change="onPlanChange()"
                    :class="{error_outline: commonState.errors.planPart2}">
              <option value="">{{ $t('select') }}</option>
              <option v-for="elem in commonState.lovs.pd_type" v-bind:value="elem.key" :key="elem.key">
                {{elem.val}}
              </option>
            </select>
          </p>
          <p>
            <span class="iferror" v-show="commonState.errors.planPart2_required">
              ▲ {{ $t('required') }}
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('bagSize') }}<span class="font_orange">*</span></p></th>
        <td>
          <p>{{ sizeForHanding }}</p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useCommonState } from '@/components/Register/composables/useCommonState'
import { getLocalizedText } from '@/utils/i18n'

export default defineComponent({
  setup() {
    const commonState = useCommonState()

    const sizeForHanding = computed(() => {
      return commonState.planPart1 === 'tx' ? getLocalizedText('master.planPart.tx') + '（45L）' : ''
    })

    // TODO 色々とcomputedに移せるような気もするが...
    function onPlanChange() {
      const state = commonState

      state.bagSize = state.lovs.size[0].key
      state.showBagSizeSelect = false
      state.isOnetime = true

      // 直接受け渡す以外は不在受け渡しとする
      const planIdSuffix = state.planPart2 === '1' ? '1' : '2'

      // 単発プランの場合、初回費用固定
      state.planId = state.planPart1 + '_' + planIdSuffix
      state.price = ''
      state.unitPrice = ''
      state.showPdSameTime = false
      state.pdSameTime = ''
    }

    return {
      commonState,
      // computed
      sizeForHanding,
      // methods
      onPlanChange,
    }
  }
})
</script>

<style lang="scss" scoped>
.wrap_input {
  width: 100%;
  margin: 0 auto 0 auto;
  text-align: left;
  overflow: hidden;
}

.wrap_input .slc {
  float: left;
  margin: 10px 15px 0 0;
}

.wrap_input .notes {
  float: left;
  width: 26px;
  height: 26px;
  margin: 10px 0 0 0;
  background-color: #B7E6F1;
  color: #ffffff;
  text-align: center;
  line-height: 26px;
  border-radius: 13px;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .wrap_input .slc {
    float: none;
  }
  .wrap_input .notes {
    float: none;
  }
}

.ml12 {
  margin-left: 12px;
}
</style>
