import { getLocalizedText } from '@/utils/i18n'

export const dateStrToDate = str => {
  if (!str) { return null; }
  if (str.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/)) {
    str = str.replace(/-/g, '').replace(/:/g, '')
    return new Date(
      parseInt(str.substr(0, 4)),
      parseInt(str.substr(4, 2)) - 1,
      parseInt(str.substr(6, 2)),
      parseInt(str.substr(9, 2)),
      parseInt(str.substr(11, 2)),
      parseInt(str.substr(13, 2)),
    )
  }
  return new Date(str.replace(/ /g, 'T'))
}

function ensureDate(dt) {
  if (!dt) { return null }

  let ret = dt
  if (typeof dt === 'string') {
    ret = dateStrToDate(dt)
  }
  return ret
}

export const dtFormat = (date, format) => {
  date = ensureDate(date)
  if (!date) { return '' }
  const y = date.getFullYear()
  const mon = date.getMonth() + 1
  const d = date.getDate()
  const youbi = getLocalizedText(`master.dayOfWeek.${date.getDay()}`)
  const h = date.getHours()
  const min = date.getMinutes()
  const s = date.getSeconds()
  return format
    .replace(/yyyy/g, y)
    .replace(/mm/g, ('0' + mon).slice(-2))
    .replace(/m/g, mon)
    .replace(/dd/g, ('0' + d).slice(-2))
    .replace(/d/g, d)
    .replace(/HH/g, ('0' + h).slice(-2))
    .replace(/H/g, h)
    .replace(/MM/g, ('0' + min).slice(-2))
    .replace(/M/g, min)
    .replace(/SS/g, ('0' + s).slice(-2))
    .replace(/S/g, s)
    // 曜日を最後に入れないと英語表記の場合はMonのMが置換されてしまう
    .replace(/a/g, youbi)
}

export const lineBreakToBR = (str) => {
  if (!str) { return '' }

  return str
    .replace(/\r\n|\\r\\n/g, '<br>')
    .replace(/\r|\\r/g, '<br>')
    .replace(/\n|\\n/g, '<br>')
}

export const splitByLineBreak = (str) => {
  if (!str) { return [] }
  return lineBreakToBR(str).split('<br>')
}
