<template>
<div class="contents_855">
  <header-bar active="wash-request"></header-bar>
  <h1 class="title2">{{ $t('washRequest.addPurchase') }}</h1>
  <div class="contents_box mg20">
    <table class="form_tab form_line_top">
      <tr>
        <th><p class="item2">{{ $t('bagSize') }}</p></th>
        <td>
          <p>{{size}}{{ $t('size') }}</p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('washRequest.quantity') }}<span class="font_orange">*</span></p></th>
        <td>
          <p>
            <select v-model="count" class="w200" @change="onCountChange()">
              <option v-for="elem in counts" v-bind:value="elem.key">
                {{elem.val}}
              </option>
            </select>
          </p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('washRequestOnetime.form.price') }}</p></th>
        <td><p>{{price | currency('',0)}}{{ $t('washRequestOnetime.form.priceUnit') }}</p></td>
      </tr>
      <tr>
        <th><p class="item3">{{ $t('paymentMethod') }}</p></th>
        <td>
          <p>
            {{payment_method_str}}{{ $t('profile.paymentSuffix') }}
          </p>
        </td>
      </tr>
    </table>

    <div class="err-msg-area">
      <p><span class="font_orange" v-for="e in serverErrors">
        {{e}}
      </span></p>
      <p><span class="font_orange" v-show="isStatusWithdrawn">
        {{ $t('accountWithdrawn') }}
      </span></p>
    </div>

    <p class="submit_bx_l">
      <router-link :to="{name:'wash-request'}" class="submit1 bg_gray">
        {{ $t('backButton') }}
      </router-link>
    </p>
    <p v-if="payment_method == 1" class="submit_bx_r">
      <button @click="addWreqCount()" class="submit1 bg_green">
        {{ $t('enterCardInfo') }}
      </button>
    </p>
    <p v-else class="submit_bx_r">
      <button @click="addWreqCount()" class="submit1 submit2 bg_green">
        {{ $t('okButton') }}
      </button>
    </p>

    <form id="payment-form" method="post"
        :action="paymentInfo.url" accept-charset="Shift_JIS">
      <input type="hidden" v-for="elem in paymentInfo.formParams"
        :name="elem.name" :value="elem.value">
      <input type="hidden" name="RETURL" :value="paymentInfo.retUrl">
    </form>
  </div>
</div>
</template>

<script>
import userApi from '@/api/user'

export default {
  name: 'buy-wash-request',
  data() {
    return {
      counts: [],
      pricePerCount: 0,
      count: 1,
      price: '',
      discount_price_for_add: 0,

      payment_method: 1,
      payment_method_str: '',

      paymentInfo: {
        url: '',
        formParams: [],
        retUrl: '',
      },

      size: '',
      sizeMap: [],
      paymentMethodMap: {},
      isStatusWithdrawn: false,
      serverErrors: [],
    }
  },
  async mounted() {
    const quantityUnitText = this.$t('washRequest.quantityUnit')
    this.counts = [...Array(20).keys()].map(e =>{
      return {key: e + 1, val: `${e + 1}${quantityUnitText}`}
    }),

    // TODO 追加購入金額値引き対応 いつか改修
    // 追加購入金額の値引きがある場合、金額を取得
    await this.$store.dispatch('user/getMe')
      .then(me => {
        if (me.discount_price_for_add) {
          this.discount_price_for_add = me.discount_price_for_add
        }
        this.payment_method = me.payment_method_id
      })

    window.master.$promise.then(mst => {
      this.lovs = mst.lovs
      this.planMap = mst.planMap
      this.sizeMap = mst.lovsMap.size
      this.paymentMethodMap = mst.lovsMap.payment_method

      this.payment_method_str = this.paymentMethodMap[this.payment_method].val

      userApi.getMyCurrentPlan().then(({ data }) => {
        const plan = this.planMap[data.plan_id]
        this.pricePerCount = plan.price_for_add
        this.discountPriceForAdd()
        this.onCountChange()
        // サイズ取得
        this.size = this.sizeMap[this.planMap[plan.plan_id].size].val
      })
    })
  },
  methods: {
    discountPriceForAdd() {
      if (this.discount_price_for_add > 0) {
          this.pricePerCount = this.discount_price_for_add
      }
    },
    onCountChange() {
      this.price = this.pricePerCount * this.count
    },
    addWreqCount() {
      this.isStatusWithdrawn = false
      this.serverErrors = []

      const data = {
        count: this.count,
        price: this.price,
        payment_method: this.payment_method,
        price_per_count: this.pricePerCount,
      }
      userApi.addWashRequestCount(data)
        .then(({ data }) => {
          //クレジット決済
          if (this.payment_method == 1) {
            this.updatePaymentFormValues(data.payment)

            setTimeout(() => {
              const form = document.querySelector('#payment-form')
              form.submit()
            }, 0)
          //請求書
          } else {
            this.$store.dispatch(
              'user/getMe',
              {forceRefresh: true}
            )
            this.$router.push({
              name: 'wash-request'
            })
          }
        })
        .catch(e => {
          if (e.response.data.reason === 'status_withdrawn') {
            this.isStatusWithdrawn = true
            this.$store.dispatch('localStorage/set', {
              key: 'serverToken',
              val: null,
            })
            return
          }
          const errRes = e.response.data.errors;
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors;
        })
    },
    updatePaymentFormValues(obj) {
      this.paymentInfo.url = obj.url
      this.paymentInfo.formParams = obj.form_params
      this.paymentInfo.retUrl = `${location.protocol}//${location.host}/${obj.ret_url_path}`
    },
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  filter: alpha(opacity=5);
  -moz-opacity:0.5;
  opacity:0.5;
}
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
</style>
