<template>
<ul class="list">
  <li><p>{{ $t('notice.washRequestOnetime.deliveryBoxPassword') }}</p></li>
  <li><p>{{ $t('notice.washRequestOnetime.confirmationEmail') }}</p></li>
  <li><p>{{ $t('notice.washRequestOnetime.minDeliveryTime') }}</p></li>
  <li><p>{{ $t('notice.washRequestOnetime.timeWindow') }}</p></li>
  <li><p>{{ $t('notice.washRequestOnetime.noChangesAfterConfirm') }}</p></li>
  <li><p>{{ $t('notice.washRequestOnetime.paidBilling') }}</p></li>
  <li><p>{{ $t('notice.washRequestOnetime.creditCheck') }}</p></li>
</ul>
</template>

<script>
export default {
  name: 'wash-request-onetime-notice-messages',
  data() {
    return {}
  },
}
</script>
