<template>
<ul class="list">
  <li v-if="!isOnetime"><p>{{ $t('notice.withdrawal.considerOneTimePlan') }}</p></li>
  <li><p>{{ $t('notice.withdrawal.endOfMonth') }}</p></li>
  <li><p>{{ $t('notice.withdrawal.noFirstMonth') }}</p></li>
  <li><p>{{ $t('notice.withdrawal.futureCancel') }}</p></li>
  <li><p>{{ $t('notice.withdrawal.noRefund') }}</p></li>
  <li><p>{{ $t('notice.withdrawal.disposeBag') }}</p></li>
</ul>
</template>

<script>
export default {
  name: 'withdrawal-notice-messages',
  props: {
    planPart1: {
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {
    isOnetime() {
      return this.planPart1 === 'tx'
    }
  },
}
</script>
