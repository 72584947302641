<template>
<footer class="foot_wrap">
  <div class="contents_wrap">
    <p class="foot_logo">
      <a href="https://awesome-wash.com">
        <img src="/static/images/logo_hd.gif" alt="AWESOME WASH">
      </a>
    </p>
    <p class="foot_info pc_br_none">
      <span class="bold">{{ $t('footer.companyInfo.serviceName') }}</span>　<br>
      {{ $t('footer.companyInfo.companyName') }}　<br>
      {{ $t('footer.companyInfo.address') }}　
      <span class="pc_br_none tab_br_none sp_br_yes"><br></span>
      {{ $t('footer.companyInfo.tel') }}
    </p>
  </div>
  <p class="copyright">{{ $t('footer.copyright') }}</p>
</footer>
</template>

<script>
export default {
  name: 'no-login-header',
}
</script>
