<template>
<div>
  <no-login-header></no-login-header>

  <h1 class="pagetitle">{{ $t('registerComplete.title') }}</h1>
  <div>
    <div class="contents_box mg20">
      <p class="mypage_notes mg30 mg_bt30 font_orange">
        {{ $t('registerComplete.thankYou') }}
      </p>
      <p class="mypage_notes mg40">
        {{ $t('registerComplete.instructionMessage') }}
      </p>
      <p class="submit_bx1">
        <a href='/wash-request' class="glnavi_btn_on">
          {{ $t('registerComplete.requestButton') }}
        </a>
      </p>
    </div>
  </div>

  <div
    data-vc-ec-id="3672710"
    :data-vc-uid="valueCommerce.dataVcUid"
  >
  </div>

</div>
</template>

<script>
import Vue from 'vue'
import userApi from '@/api/user'

export default {
  name: 'register-complete',
  data() {
    return {
      id: this.$route.params.id,
      paymentMethodId: this.$route.params.payment_method_id,

      valueCommerce: {
        dataVcUid: ''
      },
    }
  },
  created() {
    const dtFormat = Vue.filter('dtFormat')
    let uid = ('0000000000' + this.$route.params.id).slice(-10)
    let now = new Date()
    this.valueCommerce.dataVcUid = dtFormat(now, 'yyyymmdd') + uid
  },
  mounted() {
    if (this.paymentMethodId) {
      window.location = `/register-complete/${this.id}`
    } else {
      userApi.getToken(this.id).then(({ data }) => {
        this.$store.dispatch('localStorage/set', {
          key: 'serverToken',
          val: data['token']['access_token'],
        })
        // window.location = '/'
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.survey_btn{
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius:4px;
  background-color:#daa520;
  color: #ffffff;
  text-align:center;
  line-height:30px;
  text-decoration:none;
}
a.survey_btn {
  color: #ffffff;
}
a.survey_btn:link {
  display:block;
}
a.survey_btn:hover {
  color: #ffffff;
}
</style>