<template>
<ul class="list">
  <li><p>{{ $t('notice.washRequest.weekly.automaticRequest') }}</p></li>
  <li><p>{{ $t('notice.washRequest.weekly.automaticPurchase') }}</p></li>
  <li><p>{{ $t('notice.washRequest.weekly.noRefund') }}</p></li>
  <li><p>{{ $t('notice.washRequest.weekly.cancelIncrease') }}</p></li>
  <li><p>{{ $t('notice.washRequest.weekly.changeEffectiveDate') }}</p></li>
</ul>
</template>

<script>
export default {
  name: 'wash-request-notice-messages',
  props: {
    mongonType: {
      type: String
    }
  },
  data() {
    return {}
  },
}
</script>
