<template>
  <tr>
    <th><p class="item3">{{itemLabel}}<span class="font_orange">*</span></p></th>
    <td>
      <p>
        <input
          type="text"
          class="form_basic form_70p"
          :class="{error_outline: userErrorState.errors[itemName]}"
          v-model="userState[itemName]"
        />
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors[`${itemName}_required`]">
          ▲ {{ $t('required') }}
        </span>
      </p>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useUserErrorState } from '@/components/lib/user/composables/useUserErrorState'

export default defineComponent({
  props: {
    itemLabel: {
      type: String,
      required: true,
    },
    itemName: {
      type: String,
      required: true,
      validator(value) {
        return [
          'company_name',
          'company_name_kana',
          'send_addr_name',
        ].includes(value)
      },
    },
  },
  setup() {
    const { userState } = useUserState()
    const { userErrorState } = useUserErrorState()

    return {
      userState,
      userErrorState,
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
