<template>
<div class="contents_855">
  <header-bar active="profile"></header-bar>

  <div v-show="isStep1">
    <h1 class="title2">{{ $t('profile.changePassword') }}</h1>
    <div class="contents_box mg20">
      <table class="form_tab form_line_top">
        <tr>
          <th><p class="item3">{{ $t('password.label') }}<span class="font_orange">*</span></p></th>
          <td>
            <p>
              <input type="password" size="20" class="form_basic"
                v-model="params.password" :class="{error_outline: this.errors.password}"><span class="font_orange">　{{ $t('password.alphanumericNote') }}</span>
            </p>
            <p>
              <span class="iferror" v-show="errors.password_required">
                ▲ {{ $t('required') }}
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.password_invalid">
                ▲ {{ $t('password.formatError') }}
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item3">{{ $t('password.confirmation') }}</p></th>
          <td>
            <p>
              <input type="password" size="20" class="form_basic"
                v-model="params.password_confirm" :class="{error_outline: this.errors.password_confirm}"/>
            </p>
            <p>
              <span class="iferror" v-show="errors.password_confirm_required">
                ▲ {{ $t('required') }}
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.password_confirm_unmatch">
                ▲ {{ $t('password.confirmMatch') }}
              </span>
            </p>
          </td>
        </tr>
      </table>

      <div class="err-msg-area">
        <p><span class="font_orange" v-for="e in serverErrors">
          {{e}}
        </span></p>
        <p><span class="font_orange" v-show="isStatusWithdrawn">
          {{ $t('accountWithdrawn') }}
        </span></p>
      </div>

      <p class="submit_bx_l">
        <router-link :to="{name:'profile'}" class="submit1 bg_gray">
          {{ $t('backButton') }}
        </router-link>
      </p>
      <p class="submit_bx_r">
        <button @click="updatePassword()" class="submit1 bg_green">
          {{ $t('washRequest.change') }}
        </button>
      </p>
    </div>
  </div>

  <div v-show="isStep2">
    <h1 class="title2">{{ $t('profile.changePassword') }}</h1>
    <div class="contents_box mg20">
      <p class="mypage_notes mg30 mg_bt30 font_orange">
        {{ $t('profile.updatePasswordComplete') }}
      </p>
    </div>
  </div>

</div>
</template>

<script>
import userApi from '@/api/user'
import userMixin from '@/mixin/userMixin'

export default {
  name: 'password-change',
  data() {
    return {
      currentStep: 1,
      maxStep: 2,
      isStep1: true,
      isStep2: false,

      params: {
        password: '',
        password_confirm: '',
      },

      isStatusWithdrawn: false,
      errors: {},
      serverErrors: [],
    }
  },
  computed: {
    hasError() {
      return Object.keys(this.errors).length > 0
    }
  },
  mixins: [userMixin],
  methods: {
    nextStep() {
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkPassword() {
      this.errors = {}
      let result = true

      const requiredParams = [
        'password',
        'password_confirm',
      ]
      const confirmableParams = [
        'password',
      ]
      requiredParams.forEach(prop => {
        if (!this.params[prop]) {
          this.errors[prop] = true
          this.errors[`${prop}_required`] = true
          result = false
        }
      })
      confirmableParams.forEach(prop => {
        const val1 = this.params[prop]
        const val2 = this.params[`${prop}_confirm`]
        if (val1 !== val2) {
          this.errors[`${prop}_confirm`] = true
          this.errors[`${prop}_confirm_unmatch`] = true
          result = false
        }
      })

      let errObj = this.checkValidPassword(this.params.password)
      if (Object.keys(errObj).length > 0) {
        Object.keys(errObj).forEach(k => {
          this.errors[k] = errObj[k]
        })
        result = false
      }

      return result
    },
    updatePassword() {
      if (!this.checkPassword()) { return }

      this.isStatusWithdrawn = false
      this.serverErrors = []

      userApi.updateMyPassword(this.params)
        .then(({ data }) => {
          this.nextStep()
        })
        .catch(e => {
          if (e.response.data.reason === 'status_withdrawn') {
            this.isStatusWithdrawn = true
            this.$store.dispatch('localStorage/set', {
              key: 'serverToken',
              val: null,
            })
            return
          }
          const errRes = e.response.data.errors;
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors;
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  filter: alpha(opacity=5);
  -moz-opacity:0.5;
  opacity:0.5;
}
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
</style>
