<template>
  <div class="contents_box">
    <table class="form_tab">
      <tr>
        <th><p class="item2">{{ $t('register.plan.title') }}<span class="font_orange">*</span></p></th>
        <td>
          <p>
            <select v-model="commonState.planPart1" class="w200" @change="onPlanChange()"
                    :class="{error_outline: commonState.errors.planPart1}">
              <option value="">{{ $t('select') }}</option>
              <option v-for="elem in commonState.planParts1" :value="elem.key">
                {{elem.val}}
              </option>
            </select>
          </p>
          <p>
            <span class="iferror" v-show="commonState.errors.planPart1_required">
              ▲ {{ $t('required') }}
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('plan.pickupMethod') }}<span class="font_orange">*</span></p></th>
        <td>
          <p>
            <select v-model="commonState.planPart2" class="w250" @change="onPlanChange()"
                    :class="{error_outline: commonState.errors.planPart2}">
              <option value="">{{ $t('select') }}</option>
              <option v-for="elem in commonState.lovs.pd_type" v-bind:value="elem.key">
                {{elem.val}}
              </option>
            </select>
          </p>
          <p>
            <span class="iferror" v-show="commonState.errors.planPart2_required">
              ▲ {{ $t('required') }}
            </span>
          </p>
          <div v-if="commonState.showPdSameTime" class="wrap_input">
            <div class="slc">
              <input id="same_time_delivery_radio1" type="radio" value="" v-model="commonState.pdSameTime" @change="onPlanChange()">
              <label for="same_time_delivery_radio1" class="ml5">{{ $t('separate') }}</label>
            </div>
            <div class="slc">
              <input id="same_time_delivery_radio2" type="radio" value="1" v-model="commonState.pdSameTime" @change="onPlanChange()">
              <label for="same_time_delivery_radio2" class="ml5">{{ $t('sameTime') }}</label>
            </div>
            <div class="notes" @click="commonState.showSameTimeDeliveryNoteModal = true">?</div>
          </div>
        </td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('bagSize') }}<span class="font_orange">*</span></p></th>
        <td>
          <p v-if="commonState.showBagSizeSelect">
            <select v-model="commonState.bagSize" class="w200" @change="onPlanChange()"
                    :class="{error_outline: commonState.errors.bagSize}">
              <option value="">{{ $t('select') }}</option>
              <option v-for="elem in commonState.sizeDisplay" :value="elem.key">
                {{elem.display}}
              </option>
            </select>
          </p>
          <p v-else>
            {{sizeForHanding}}
          </p>
          <p>
            <span class="iferror" v-show="commonState.errors.bagSize_required">
              ▲ {{ $t('required') }}
            </span>
          </p>
        </td>
      </tr>

      <tr v-if="!commonState.isOnetime">
        <th><p class="item2">{{ $t('register.plan.planFee') }}</p></th>
        <td><p>{{commonState.price | currency('',0)}}{{ $t('washRequestOnetime.form.priceUnit') }}（{{commonState.unitPrice | currency('',0)}}{{ $t('washRequestOnetime.form.pricePerUnit') }}）</p></td>
      </tr>

      <template v-if="!commonState.isOnetime && commonState.bagSize">
        <tr v-if="bagSizeAlphabetValue === 'S'">
          <th><p class="item2">{{ $t('register.plan.bagFee') }}</p></th>
          <td>
            <p>
              <span>0{{ $t('washRequestOnetime.form.priceUnit') }}</span>
              <span class="diblk ml12">({{ $t('register.plan.freeFirst') }})</span>
            </p>
            <p>{{ $t('register.plan.additionalBagPrice', { price: bagPrice }) }}</p>
          </td>
        </tr>
        <tr v-else>
          <th><p class="item2">{{ $t('register.plan.bagFee') }}</p></th>
          <td>
            <p>{{(bagPrice * numBags) | currency('',0)}}{{ $t('washRequestOnetime.form.priceUnit') }}（{{bagPrice | currency('',0)}}{{ $t('washRequestOnetime.form.priceUnit') }}×{{numBags}}{{ $t('washRequest.unit') }}）</p>
            <p v-if="numBags > 1">{{ $t('register.plan.multipleBagsNote') }}</p>
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import { computed, defineComponent, watch } from 'vue'
import { useCommonState } from '@/components/Register/composables/useCommonState'
import { getLocalizedText } from '@/utils/i18n'

export default defineComponent({
  setup() {
    const commonState = useCommonState()

    const numBags = computed(() => {
      // 集配同時は2個
      return commonState.pdSameTime === '1' ? 2 : 1
    })
    const bagSizeAlphabetValue = computed(() => {
      const obj = commonState.sizeDisplay[commonState.bagSize]
      if (!obj) { return '' }
      return commonState.sizeDisplay[commonState.bagSize].val
    })
    const bagPrice = computed(() => {
      const obj = (commonState.lovsMap.price_for_add_bag || {})[commonState.bagSize]
      if (!obj) { return 0 }
      return obj.val
    })
    const sizeForHanding = computed(() => {
      return commonState.planPart1 === 'tx' ? getLocalizedText('master.planPart.tx') + '（45L）' : ''
    })

    // TODO 色々とcomputedに移せるような気もするが...
    function onPlanChange() {
      const state = commonState

      if (state.planPart1 === 'tx') {
        state.bagSize = state.lovs.size[0].key
        state.showBagSizeSelect = false
        state.isOnetime = true
      } else {
        state.isOnetime = false
        state.showBagSizeSelect = true
      }

      if (!state.planPart1 || !state.planPart2 || !state.bagSize || !state.count) {
        if (state.planPart1 && state.planPart2) {
          state.showPdSameTime = true
        }
        state.price = ''
        state.unitPrice = ''
        return
      }
      // 直接受け渡す以外は不在受け渡しとする
      const planIdSuffix = state.planPart2 === '1' ? '1' : '2'

      //Sサイズ以外はplanIdにサイズを追記する
      let bagSizeSuffix = ''
      const sizeMap = state.lovsMap.size
      if (state.bagSize != 1) {
        bagSizeSuffix = '_' + sizeMap[state.bagSize].val
      }

      // 単発プランの場合、初回費用固定
      if (state.planPart1 === 'tx') {
        state.planId = state.planPart1 + '_' + planIdSuffix
        state.price = ''
        state.unitPrice = ''
        state.showPdSameTime = false
        state.pdSameTime = ''
        return
      }
      // お試しプランの場合、初回費用固定
      if (state.planPart1 === 't0') {
        state.planId = state.planPart1 + '_' + planIdSuffix
        state.price = state.other['INITIAL_PRICE_T0']
        state.unitPrice = ''
        state.showPdSameTime = false
        state.pdSameTime = ''
        return
      }
      // 月２個プランの場合
      if (state.planPart1 === 't2') {
        state.planId = state.planPart1 + '_' + planIdSuffix + bagSizeSuffix
        state.price = state.planMap[state.planId].price * state.count
        state.unitPrice = state.price / state.planMap[state.planId].wash_count
        state.showPdSameTime = false
        state.pdSameTime = ''
        return
      }
      // 配達と次回の集配の集配を同時にする場合、末尾に付与
      state.showPdSameTime = true
      const planIdSuffix2 = state.pdSameTime === '1' ? '_1' : ''
      state.planId = state.planPart1 + '_' + planIdSuffix + planIdSuffix2 + bagSizeSuffix
      if (!state.planMap[state.planId]) {
        state.price = ''
        state.unitPrice = ''
        return
      }

      state.price = state.planMap[state.planId].price * state.count
      state.unitPrice = state.price / state.planMap[state.planId].wash_count
    }

    watch(() => commonState.bagSize, (newBagSize) => {
      commonState.planParts1 = commonState.lovs.plan_part.filter(p => {
        // お試しプランは選択不可
        if (p['key'] === 't0') {
          return false
          // bagSizeがS,Mの場合は40、48、56個を選択できないようにする
        } else if ((newBagSize == 1 || newBagSize == 2) && (p['key'] === 't40' || p['key'] === 't48' || p['key'] === 't56')) {
          return false
        }
        return true
      })

      // 40、48、56個を選択した状態で、bagSizeをS,Mに変更した場合は個数をリセットする
      if ((newBagSize == 1 || newBagSize == 2) && (commonState.planPart1 === 't40' || commonState.planPart1 === 't48' || commonState.planPart1 === 't56')) {
        commonState.planPart1 = ''
      }
    })

    return {
      commonState,
      // computed
      numBags,
      bagSizeAlphabetValue,
      bagPrice,
      sizeForHanding,
      // methods
      onPlanChange,
    }
  }
})
</script>

<style lang="scss" scoped>
.wrap_input {
  width: 100%;
  margin: 0 auto 0 auto;
  text-align: left;
  overflow: hidden;
}

.wrap_input .slc {
  float: left;
  margin: 10px 15px 0 0;
}

.wrap_input .notes {
  float: left;
  width: 26px;
  height: 26px;
  margin: 10px 0 0 0;
  background-color: #B7E6F1;
  color: #ffffff;
  text-align: center;
  line-height: 26px;
  border-radius: 13px;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .wrap_input .slc {
    float: none;
  }
  .wrap_input .notes {
    float: none;
  }
}

.ml12 {
  margin-left: 12px;
}
</style>
