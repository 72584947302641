<template>
<div class="contents_855">
  <header-bar active="wash-request-currents"></header-bar>

  <h1 class="title2">{{ $t('washRequestChange.title') }}</h1>

  <div class="contents_box" v-show="isStep1">
    <p class="title3">{{ $t('washRequestChange.requestNumber') }}{{washReq.dispNum}}</p>
    <table class="form_tab form_line_top">
      <tr>
        <th class="form_line_bt"><p class="item2">{{ $t('washRequestChange.pickupDeliveryTime') }}</p></th>
        <td class="form_line_bt">
          <p class="pc_br_none tab_br_none sp_br_yes">{{ $t('washRequestChange.pickup') }}：<br>
            <select v-model="pickupMonth" @change="setPickupDates()" class="w55"
                :disabled="!washReq.canChangePickupDt">
              <option v-for="elem in months" v-bind:value="elem">
                {{elem}}
              </option>
            </select>{{ $t('date.month') }}
            <select v-model="pickupDate" class="w55"
                :disabled="!washReq.canChangePickupDt">
              <option v-for="elem in pickupDates" v-bind:value="elem">
                {{elem}}
              </option>
            </select>{{ $t('date.day') }}
            <select v-show="!areaInfoSameTime" v-model="pickupHour" @change="setPickupMinutes()" class="w55"
                :disabled="!washReq.canChangePickupDt">
              <option v-for="elem in convHours" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
            <span class="ml20" v-show="areaInfoSameTime">{{pickupHourView}}</span>
            ：
            <select v-show="!areaInfoSameTime" v-model="pickupMinute" class="w55"
                :disabled="!washReq.canChangePickupDt">
              <option v-for="elem in pickupMinutes" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
            <span v-show="areaInfoSameTime">{{pickupMinuteView}}</span>
          </p>

          <p class="mg10 pc_br_none tab_br_none sp_br_yes">{{ $t('washRequestChange.delivery') }}：<br>
            <span v-if="pdSameTime">
              <span class="font_orange">{{ $t('washRequest.willDeliverOnNextPickup') }}</span>
            </span>
            <span v-else>
              <select v-model="deliveryMonth" @change="setDeliveryDates()" class="w55"
                  :disabled="!washReq.canChangeDeliveryDt">
                <option v-for="elem in months" v-bind:value="elem">
                  {{elem}}
                </option>
              </select>{{ $t('date.month') }}
              <select v-model="deliveryDate" class="w55"
                  :disabled="!washReq.canChangeDeliveryDt">
                <option v-for="elem in deliveryDates" v-bind:value="elem">
                  {{elem}}
                </option>
              </select>{{ $t('date.day') }}
              <select v-show="!areaInfoSameTime" v-model="deliveryHour" @change="setDeliveryMinutes()" class="w55"
                  :disabled="!washReq.canChangeDeliveryDt">
                <option v-for="elem in convHours" v-bind:value="elem.val">
                  {{elem.disp}}
                </option>
              </select>
              <span class="ml20" v-show="areaInfoSameTime">{{deliveryHourView}}</span>
              ：
              <select v-show="!areaInfoSameTime" v-model="deliveryMinute" class="w55"
                  :disabled="!washReq.canChangeDeliveryDt">
                <option v-for="elem in deliveryMinutes" v-bind:value="elem.val">
                  {{elem.disp}}
                </option>
              </select>
              <span v-show="areaInfoSameTime">{{deliveryMinuteView}}</span>
            </span>
          </p>
          <span class="font_orange">{{areaInfo.bikou}}</span>
        </td>
      </tr>
      <tr>
        <th class="form_line_bt"><p class="item2">{{ $t('washRequest.deliveryBoxPassword') }}</p></th>
        <td class="form_line_bt">
          <p class="pc_br_none tab_br_none sp_br_yes">
            <input type="text" v-model="deliveryBoxPw" class="form_basic">
          </p>
        </td>
      </tr>
    </table>
    <table class="form_tab">
      <tr>
        <th class="form_line_l"></th>
        <td class="form_line_r">
          <wash-request-notice-messages>
          </wash-request-notice-messages>
        </td>
      </tr>
    </table>
    <div class="err-msg-area">
      <span class="font_orange" v-show="isDupRequest">
        {{ $t('washRequestChange.errors.duplicateRequest') }}
      </span>
      <span class="font_orange" v-show="showErrMsg1">
        {{ $t('washRequestChange.errors.invalidDate') }}
      </span>
      <span class="font_orange" v-show="showErrMsg2">
        {{ $t('washRequestChange.errors.changeTimeLimit') }}
      </span>
      <span class="font_orange" v-show="showErrMsg3">
        {{ $t('washRequestChange.errors.minDeliveryTime') }}
      </span>
      <span class="font_orange" v-show="showErrMsg4">
        {{ $t('washRequestChange.errors.deliveryTimeLimit') }}
      </span>
      <span class="font_orange" v-show="showErrMsg5">
        {{ $t('washRequestChange.errors.areaUnavailable') }}
      </span>
      <span class="font_orange" v-show="showErrMsg6">
        {{ $t('washRequestChange.errors.invalidAreaTime') }}
      </span>
      <p><span class="font_orange" v-show="isPreqCountMax">
        {{ $t('washRequestChange.errors.maxRequestsReached') }}
      </span></p>
    </div>
    <p class="submit_bx1">
      <button @click="nextStep()" class="submit1 bg_green">
        {{ $t('confirmButton') }}
      </button>
    </p>
  </div>

  <div class="contents_box" v-show="isStep2">
    <p class="mypage_notes mg30 mg_bt30">
      <span class="font_orange">
        {{ $t('washRequestChange.confirmMessage') }}
      </span>
    </p>

    <p class="title3">{{ $t('washRequestChange.requestNumber') }}{{washReq.dispNum}}</p>
    <table class="form_tab form_line_top">
      <tr>
        <th class="form_line_l"><p class="item2">{{ $t('washRequestChange.pickupDeliveryTime') }}</p></th>
        <td class="form_line_r">
          <p>
            {{ $t('washRequestChange.pickup') }}：{{pickupDt | dtFormat($t('dateTimeFormat.shortDateTimeWithWeekday'))}}
          </p>
          <p class="mg10">
            <span v-if="pdSameTime">
              {{ $t('washRequestChange.delivery') }}：<span class="font_orange">{{ $t('washRequest.willDeliverOnNextPickup') }}</span>
            </span>
            <span v-else>
              {{ $t('washRequestChange.delivery') }}：{{deliveryDt | dtFormat($t('dateTimeFormat.shortDateTimeWithWeekday'))}}
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <th class="form_line_l"><p class="item2">{{ $t('washRequest.deliveryBoxPassword') }}</p></th>
        <td class="form_line_r">
          <p>
            {{deliveryBoxPw}}
          </p>
        </td>
      </tr>
    </table>
    <div class="err-msg-area">
      <span class="font_orange" v-show="isDupRequest">
        {{ $t('washRequestChange.errors.duplicateRequest') }}
      </span>
      <span class="font_orange" v-show="isStatusWithdrawn">
        {{ $t('accountWithdrawn') }}
      </span>
      <span class="font_orange" v-show="showErrMsg1">
        {{ $t('washRequestChange.errors.invalidDate') }}
      </span>
      <span class="font_orange" v-show="showErrMsg2">
        {{ $t('washRequestChange.errors.pickupTimeChange') }}
      </span>
      <span class="font_orange" v-show="showErrMsg3">
        {{ $t('washRequestChange.errors.deliveryTimeChange') }}
      </span>
      <span class="font_orange" v-show="showErrMsg4">
        {{ $t('washRequestChange.errors.deliveryTimeLimit') }}
      </span>
      <span class="font_orange" v-show="showErrMsg5">
        {{ $t('washRequestChange.errors.areaUnavailable') }}
      </span>
      <span class="font_orange" v-show="showErrMsg6">
        {{ $t('washRequestChange.errors.invalidAreaTime') }}
      </span>
      <p><span class="font_orange" v-show="isPreqCountMax">
        {{ $t('washRequestChange.errors.maxRequestsReached') }}
      </span></p>
    </div>
    <p class="submit_bx_l">
      <button @click="prevStep()" class="submit1 bg_gray">
        {{ $t('backButton') }}
      </button>
    </p>
    <p class="submit_bx_r">
      <button @click="updateWashRequest()" class="submit1 bg_green">
        {{ $t('okButton') }}
      </button>
    </p>
  </div>

  <div class="contents_box" v-show="isStep3">
    <p class="mypage_notes mg40 mg_bt40 font_orange" v-html="$t('washRequest.requestComplete')">
    </p>
    <p class="mypage_notes mg40">
      {{ $t('washRequestChange.emailConfirmation') }}<br>
      <span v-html="$t('washRequestChange.checkDeliveryNotes')"></span>
    </p>
  </div>
</div>
</template>

<script>
import Vue from 'vue'
import dateMixin from '@/mixin/dateMixin'
import washRequestMixin from '@/mixin/washRequestMixin'
import userApi from '@/api/user'
import areaInfoApi from '@/api/areaInfo'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'wash-request-change',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    return {
      washReq: {},

      currentStep: 1,
      maxStep: 3,
      isStep1: true,
      isStep2: false,
      isStep3: false,

      planId: '',

      pdSameTime: false,
      months: window.VueMonthOptions,
      dates: window.VueDateOptions,
      hours: window.VueHourOptions,
      minutes: this.setMinuteOption([0,30]),
      minutes2: this.setMinuteOption([0]),
      pickupDates: [],
      deliveryDates: [],
      convHours: window.VueHourOptions,
      pickupMinutes: this.setMinuteOption([0,30]),
      deliveryMinutes: this.setMinuteOption([0,30]),
      areaInfo: [],
      sHour: 10,
      sMinute: 0,
      eHour: 10,
      eMinute: 0,
      areaInfoSameTime: false,

      //counts: [
      //  1,2,3,4,5
      //],
      pickupYear: 9999,
      pickupMonth: 1,
      pickupDate: 1,
      pickupHour: 12,
      pickupMinute: 0,
      pickupHourView: 12,
      pickupMinuteView: 0,
      pickupDt: null,
      deliveryYear: 9999,
      deliveryMonth: 1,
      deliveryDate: 1,
      deliveryHour: 12,
      deliveryMinute: 0,
      deliveryHourView: 12,
      deliveryMinuteView: 0,
      deliveryDt: null,
      deliveryBoxPw: '',
      businessHolidays: [],

      showErrMsg1: false,
      showErrMsg2: false,
      showErrMsg3: false,
      showErrMsg4: false,
      showErrMsg5: false,
      showErrMsg6: false,
      isDupRequest: false,
      isStatusWithdrawn: false,
      isPreqCountMax: false,
      isSaturdayAvailableWeek: 1,
    }
  },
  computed: {
    ...mapState('user', {
      isSaturdayAvailable: state => state.is_saturday_available,
      zipcode: state => state.send_addr_zipcode,
    }),
  },
  async mounted() {
    // 休業日取得
    this.businessHolidays = await this.getBusinessHolidays()

    await this.getMe()

    window.master.$promise.then(mst => {
      userApi.getMyWashRequests().then(({ data }) => {
        const washReqs = this.getWashRequestCurrents(data)
        washReqs.forEach(e => {
          if (parseInt(e.id) === parseInt(this.id)) {
            this.washReq = e
            // 都度が曜日の判別
            if (this.washReq.request_type == 1) {
              this.isSaturdayAvailableWeek = this.isSaturdayAvailable
            }
          }
        })
        this.setSelectOptions()
      })
    })
  },
  mixins: [washRequestMixin, dateMixin],
  methods: {
    setMinuteOption(key) {
      if (!key) {
        return window.VueMinuteOptions
      }
      let filteredOptions = window.VueMinuteOptions.filter(option => key.includes(option.val));
      return filteredOptions
    },
    async getMe() {
      await this.$store.dispatch('user/getMe')
        .then(me => {
        this.planId = me.user_plan.plan_id
      })
    },
    setSelectOptions() {
      if (!this.washReq) { return }

      const planInfos = this.planId.split('_')
      const isOnetime = planInfos[0] == 'tx' ? 1 : 0
      const obj = {
        'zipcode': this.zipcode,
        'is_onetime': isOnetime
      }
      areaInfoApi.getAreaInfo(obj).then(({ data }) => {
        this.areaInfo = data

        if (!data['error']) {
          let sTimes = this.areaInfo.start_time.split(':')
          this.sHour = sTimes[0]
          this.sMinute = sTimes[1] == 0 ? 0 : sTimes[1]
          let eTimes = this.areaInfo.end_time.split(':')
          this.eHour = eTimes[0]
          this.eMinute = eTimes[1] == 0 ? 0 : eTimes[1]
          // 都度依頼かつ15:00~22:00のエリアの場合のみ、選択開始時間を1時間遅らせる
          if (sTimes[0] == 15 && sTimes[1] == 0 && eTimes[0] == 22 && eTimes[1] == 0 && this.washReq.request_type == 1) {
            this.sHour = Number(sTimes[0]) + 1
          }
        // } else {
        //   this.sMinute = "00"
        }

        if (this.sHour ==this.eHour && this.sMinute == this.eMinute) {
          this.areaInfoSameTime = true
          // if (this.sMinute == 0) {
          //   this.sMinute = "00"
          // }
        }

        let convHoursRe = []
        this.convHours.forEach(v => {
          if (v.val >=  this.sHour && v.val <= this.eHour) {
            convHoursRe.push(v);
          }
        })
        this.convHours = convHoursRe
      })

      const dateStrToDate = Vue.filter('dateStrToDate')

      const pickupDt = dateStrToDate(this.washReq.sched_pickup_dt)
      this.pickupYear = pickupDt.getFullYear()
      this.pickupMonth = pickupDt.getMonth() + 1
      this.pickupDate = pickupDt.getDate()
      this.pickupHour = pickupDt.getHours()
      this.pickupMinute = pickupDt.getMinutes()
      if (!this.pickupMinutes.includes(this.pickupMinute)) {
        this.pickupMinute = 0
      }

      const deliveryDt = dateStrToDate(this.washReq.sched_delivery_dt)
      this.deliveryYear = deliveryDt.getFullYear()
      this.deliveryMonth = deliveryDt.getMonth() + 1
      this.deliveryDate =  deliveryDt.getDate()
      this.deliveryHour =  deliveryDt.getHours()
      this.deliveryMinute = deliveryDt.getMinutes()
      if (!this.deliveryMinutes.includes(this.deliveryMinute)) {
        this.deliveryMinute = 0
      }
      if (this.areaInfoSameTime) {
        this.pickupHourView = this.pickupHour;
        this.pickupMinuteView = this.pickupMinute == 0 ? "00" : this.pickupMinute;
        this.pickupHour = this.sHour;
        this.pickupMinute = this.sMinute;
        this.deliveryHourView = this.deliveryHour;
        this.deliveryMinuteView = this.deliveryMinute == 0 ? "00" : this.deliveryMinute;
        this.deliveryHour = this.sHour;
        this.deliveryMinute = this.sMinute;
      }
      this.setPickupDates()
      this.setPickupMinutes()
      this.setDeliveryDates()
      this.setDeliveryMinutes()

      this.deliveryBoxPw = this.washReq.delivery_box_pw;

      userApi.getMyCurrentPlan().then(({ data }) => {
        // 月途中にプラン変更（通常・集配同時）した場合に、
        // 現在プランが最終変更プランとなってしまうため、
        // 集配同時プランかどうかの判断は一時的にこっちを使用する。後で見直す
        //plan_idを_で区切って３つ目の値が1なら集配同時
        //（M~LLサイズの集配別は３つ目にサイズ情報が入る）
        const planInfos = data.plan_id.split('_')
        if (planInfos.length > 2 && planInfos[2] == 1) {
          this.pdSameTime = true
        } else {
          this.pdSameTime = false
        }
      })
    },
    nextStep() {
      if (!this.checkCurrentStep()) { return }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    setPickupDates() {
      // 対象年月から休業日を除く日付を取得
      const yearAdjust = this.isNextYear(this.pickupYear, this.pickupMonth) ? 1 : 0
      this.pickupDates = this.getWeekDaysList(this.pickupYear + yearAdjust, this.pickupMonth, this.businessHolidays, this.isSaturdayAvailableWeek)
      if (this.pickupDates.indexOf(this.pickupDate) === -1) {
        const pickupDt = new Date(
          this.pickupYear + yearAdjust, this.pickupMonth - 1, this.pickupDates[0],
          this.pickupHour, this.pickupMinute, 0)
        this.pickupDt = this.getAvailableDate(pickupDt, this.businessHolidays, this.isSaturdayAvailableWeek)
        this.pickupDate = this.pickupDt.getDate()
      }
    },
    setDeliveryDates() {
      // 対象年月から休業日を除く日付を取得
      const yearAdjust = this.isNextYear(this.deliveryYear, this.deliveryMonth) ? 1 : 0
      this.deliveryDates = this.getWeekDaysList(this.deliveryYear + yearAdjust, this.deliveryMonth, this.businessHolidays, this.isSaturdayAvailableWeek)
      if (this.deliveryDates.indexOf(this.deliveryDate) === -1) {
        const deliveryDt = new Date(
          this.deliveryYear + yearAdjust, this.deliveryMonth - 1, this.deliveryDates[0],
          this.deliveryHour, this.deliveryMinute, 0)
        this.deliveryDt = this.getAvailableDate(deliveryDt, this.businessHolidays, this.isSaturdayAvailableWeek)
        this.deliveryDate = this.deliveryDt.getDate()
      }
    },
    setPickupMinutes() {
      if (this.hours[this.hours.length - 1].val === this.pickupHour) {
        // 22時を選択した場合、00分まで選択可能とする
        this.pickupMinute = this.setMinute(this.pickupMinute)
        this.pickupMinutes = this.minutes2
      } else if (this.convHours[this.convHours.length - 1].val === this.pickupHour) {
        // end_timeの分が00分で、その時間が選択された場合は00分まで選択可能とする
        if (this.eMinute == 0) {
          this.pickupMinute = this.setMinute(this.pickupMinute)
          this.pickupMinutes = this.minutes2
        }
      } else {
        this.pickupMinutes = this.minutes
      }
    },
    setDeliveryMinutes() {
      if (this.hours[this.hours.length - 1].val === this.deliveryHour) {
        // 22時を選択した場合、00分まで選択可能とする
        this.deliveryMinute = this.setMinute(this.deliveryMinute)
        this.deliveryMinutes = this.minutes2
      } else if (this.convHours[this.convHours.length - 1].val === this.deliveryHour) {
        // end_timeの分が00分で、その時間が選択された場合は00分まで選択可能とする
        if (this.eMinute == 0) {
          this.deliveryMinute = this.setMinute(this.deliveryMinute)
          this.deliveryMinutes = this.minutes2
        }
      } else {
        this.deliveryMinutes = this.minutes
      }
    },
    setMinute(minute) {
      return this.minutes2[this.minutes2.length - 1].val < minute ? 0 : minute
    },
    checkStep1() {
      this.resetLocalErrMsgs()
      this.resetServerErrMsgs()

      // 提供エリア外の場合は依頼不可
      if (this.areaInfo['error']) {
        this.showErrMsg5 = true
        return false
      }

      // 提供エリア情報の開始時間と終了時間が同じ場合修正できない
      if (this.areaInfoSameTime) {
        if (this.pdSameTime) {
          if (this.pickupHourView != this.sHour
            || this.pickupMinuteView != this.sMinute) {
            this.showErrMsg6 = true
            return false
          }
        } else {
          if (this.pickupHourView != this.sHour
            || this.pickupMinuteView != this.sMinute
            || this.deliveryHourView != this.sHour
            || this.deliveryMinuteView != this.sMinute) {
            this.showErrMsg6 = true
            return false
          }
        }
      }

      const now = new Date()
      let yearAdjust = this.isNextYear(this.pickupYear, this.pickupMonth) ? 1 : 0
      const pickupDt = new Date(
        this.pickupYear + yearAdjust, this.pickupMonth - 1, this.pickupDate,
        this.pickupHour, this.pickupMinute, 0)
      yearAdjust = this.isNextYear(this.deliveryYear, this.deliveryMonth) ? 1 : 0
      const deliveryDt = new Date(
        this.deliveryYear + yearAdjust, this.deliveryMonth - 1, this.deliveryDate,
        this.deliveryHour, this.deliveryMinute, 0)

      if (isNaN(pickupDt.valueOf()) || isNaN(deliveryDt.valueOf())) {
        this.showErrMsg1 = true
        return false
      }

      // Business Rule No.22 変更する際は当初の時間から2時間後以降しか選べない
      // Business Rule No.35 集配時間の変更は後ろ倒しのみで、前倒しは不可
      const dateStrToDate = Vue.filter('dateStrToDate')
      const origPickupDt = dateStrToDate(this.washReq.sched_pickup_dt)
      const origDeliveryDt = dateStrToDate(this.washReq.sched_delivery_dt)
      if (origPickupDt.valueOf() !== pickupDt.valueOf()) {
        let pickupMinDt = new Date(origPickupDt.valueOf() + 3600*2*1000)
        if (pickupDt < pickupMinDt) {
          this.showErrMsg2 = true
          return false
        }
      }

      if (this.pdSameTime) {
        // 集配同時の場合は配達時間のチェックに引っかからないs
        deliveryDt.setFullYear(2099)
      }

      if (origDeliveryDt.valueOf() !== deliveryDt.valueOf()) {
        let deliveryMinDt = new Date(origDeliveryDt.valueOf() + 3600*2*1000)
        if (deliveryDt < deliveryMinDt) {
          this.showErrMsg2 = true
          return false
        }
      }

      // Business Rule No.4 配達時間は最短でも集荷時間の48時間後以降しか選べない
      const minDiff = 86400*2*1000
      const result = deliveryDt.valueOf() - pickupDt.valueOf() >= minDiff
      if (!result) {
        this.showErrMsg3 = true
        return false
      }

      // かつ配達時間は休業日を除く48時間後しか依頼できない
      const workingDay = this.getWorkingDay(pickupDt, deliveryDt, this.businessHolidays, this.isSaturdayAvailableWeek)
      if (workingDay < 3) {
        this.showErrMsg3 = true
        return false
      }

      // Business Rule No.4.1 配達時間は最長で30日後まで選択可能
      let deliveryMaxDt = new Date(now.valueOf() + 3600*24*30*1000)
      if (!this.pdSameTime) {
        // 集配同時の場合は除く
        if (deliveryDt > deliveryMaxDt) {
          this.showErrMsg4 = true
          return false
        }
      }

      this.pickupDt = pickupDt
      this.deliveryDt = deliveryDt
      return true
    },
    checkStep2() {
      return true
    },
    updateWashRequest() {
      this.isDupRequest = false
      if (!this.checkStep1()) { return }

      const id = this.washReq.id
      const dtFormat = Vue.filter('dtFormat')
      const data = {}
      if (this.washReq.canChangePickupDt) {
        data.sched_pickup_dt = dtFormat(
          this.pickupDt, 'yyyy-mm-dd HH:MM:SS')
      }
      if (this.washReq.canChangeDeliveryDt) {
        data.sched_delivery_dt = dtFormat(
          this.deliveryDt, 'yyyy-mm-dd HH:MM:SS')
      }
      data.delivery_box_pw = this.deliveryBoxPw

      userApi.updateWashRequest(id, data).then(({ data }) => {
        this.nextStep()
        this.$store.dispatch(
          'user/getMe',
          {forceRefresh: true}
        )
      }).catch(err => {
        if (err.response.data.reason === 'dup') {
          this.isDupRequest = true
        }
        if (err.response.data.reason === 'status_withdrawn') {
          this.isStatusWithdrawn = true
          this.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: null,
          })
        }
        if (err.response.data.reason === 'preq_count_max') {
          this.isPreqCountMax = true
        }
      })
    },
    resetLocalErrMsgs() {
      this.showErrMsg1 = false
      this.showErrMsg2 = false
      this.showErrMsg3 = false
      this.showErrMsg4 = false
      this.showErrMsg5 = false
      this.showErrMsg6 = false
    },
    resetServerErrMsgs() {
      this.isDupRequest = false
      this.isStatusWithdrawn = false
      this.isPreqCountMax = false
    },
  },
}
</script>

<style lang="scss" scoped>
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.submit_bx1, .submit_bx_l, .submit_bx_r {
  margin: 12px auto;
}
.form_tab {
  .col2 {
    width: 184px;
  }
}
</style>
