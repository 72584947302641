<template>
<div class="pagination-wrapper">
  <paginate v-if="showPager"
    :page-count="pageCount"
    :page-range="pageRange"
    :margin-pages="marginPages"
    :click-handler="onPageClick"
    :prev-text="$t('pagination.prev')"
    :next-text="$t('pagination.next')"
    :container-class="pagerContainerClass"
    :page-class="pagerPageClass">
  </paginate>
</div>
</template>

<script>
import Paginate from 'vuejs-paginate'

export default {
  name: 'pager',
  props: {
    itemCount: {
      type: Number,
      required: true
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    pageRange: {
      type: Number,
      default: 8
    },
    marginPages: {
      type: Number,
      default: 1
    },
    pagerContainerClass: {
      type: String,
      default: 'pagination'
    },
    pagerPageClass: {
      type: String,
      default: 'page-item'
    }
  },
  data () {
    return {
    }
  },
  computed: {
    showPager() {
      return this.itemCount > 0
    },
    pageCount() {
      return Math.ceil(this.itemCount / this.itemsPerPage)
    },
  },
  methods: {
    onPageClick(e) {
      this.$emit('click', e)
    }
  },
  components: { Paginate }
}
</script>

<style lang="scss">
</style>
