<template>
  <a @click="switchLanguage" class="radius4">
    <font-awesome-icon icon="fa-solid fa-globe" />
    {{ displayLanguage }}
  </a>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  JAPANESE_CODE,
  ENGLISH_CODE,
  JAPANESE_DISPLAY,
  ENGLISH_DISPLAY,
} from '@/consts/localization'
import userApi from '@/api/user'

export default {
  name: 'localization-switcher',
  data() {
    return {
      currentLanguage: JAPANESE_CODE,
    }
  },
  mounted() {
    this.currentLanguage = this.$i18n.locale
  },
  computed: {
    displayLanguage() {
      return this.currentLanguage === JAPANESE_CODE ? ENGLISH_DISPLAY : JAPANESE_DISPLAY
    },
    ...mapGetters('user', [
      'isLoggedIn'
    ])
  },
  methods: {
    async switchLanguage() {
      const newLang = this.currentLanguage === JAPANESE_CODE ? ENGLISH_CODE : JAPANESE_CODE
      await this.$store.dispatch('localStorage/set', {
        key: 'localization',
        val: newLang,
      })
      this.$i18n.locale = newLang
      if (this.isLoggedIn) {
        await userApi.updateCurrentLang({
          'current_lang': newLang,
        })
      }
      window.location.reload()
    }
  }
}
</script>

<style scoped>
a {
  color: #fff;
  background-color: #00a9ca;
  font-size: 16px;
  margin-left: 4px;
  padding: 4px 12px 4px 12px;
  text-decoration: none;
}
</style>
