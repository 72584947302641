<template>
  <div class="contents_box">
    <table class="form_tab">
      <tr>
        <th><p class="item2">{{ $t('register.plan.title') }}<span class="font_orange">*</span></p></th>
        <td><p>{{planPart1Disp}}</p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('plan.pickupMethod') }}<span class="font_orange">*</span></p></th>
        <td><p>{{planPart2Disp}}</p>
        </td>
      </tr>
      <tr v-if="!commonState.isOnetime">
        <th><p class="item2">{{ $t('register.plan.planFee') }}</p></th>
        <td>
          <p>{{commonState.price | currency('',0)}}{{ $t('plan.priceUnit')}}（{{commonState.unitPrice | currency('',0)}}{{ $t('plan.pricePerUnit')}}）</p>
        </td>
      </tr>
      <template v-if="!commonState.isOnetime && commonState.bagSize">
        <tr v-if="bagSizeAlphabetValue === 'S'">
          <th><p class="item2">{{ $t('register.plan.bagFee') }}</p></th>
          <td>
            <p>
              <span>0{{ $t('plan.priceUnit') }}</span>
              <span class="diblk ml12">({{ $t('register.plan.freeFirst') }})</span>
            </p>
            <p>{{ $t('register.plan.additionalBagPrice', { price: bagPrice }) }}</p>
          </td>
        </tr>
        <tr v-else>
          <th><p class="item2">{{ $t('register.plan.bagFee') }}</p></th>
          <td>
            <p>{{(bagPrice * numBags) | currency('',0)}}{{ $t('plan.priceUnit')}}（{{bagPrice | currency('',0)}}{{ $t('plan.priceUnit')}}×{{numBags}}{{ $t('plan.unit')}}）</p>
            <p v-if="numBags > 1">{{ $t('register.plan.multipleBagsNote') }}</p>
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useCommonState } from '@/components/Register/composables/useCommonState'

export default defineComponent({
  setup() {
    const commonState = useCommonState()

    const planPart1Disp = computed(() => {
      const plan = commonState.planMap[commonState.planId]
      if (!plan) { return '' }
      return plan.name
    })
    const planPart2Disp = computed(() => {
      const pdType = commonState.lovs.pd_type
      if (!pdType) { return '' }
      let ret = ''
      pdType.forEach(e => {
        if (e.key === commonState.planPart2) {
          ret = e.val
        }
      })
      return ret
    })
    const numBags = computed(() => {
      // 集配同時は2個
      return commonState.pdSameTime === '1' ? 2 : 1
    })
    const bagSizeAlphabetValue = computed(() => {
      const obj = commonState.sizeDisplay[commonState.bagSize]
      if (!obj) { return '' }
      return commonState.sizeDisplay[commonState.bagSize].val
    })
    const bagPrice = computed(() => {
      const obj = (commonState.lovsMap.price_for_add_bag || {})[commonState.bagSize]
      if (!obj) { return 0 }
      return obj.val
    })

    return {
      commonState,
      // computed
      planPart1Disp,
      planPart2Disp,
      numBags,
      bagSizeAlphabetValue,
      bagPrice,
    }
  }
})
</script>

<style lang="scss" scoped>

</style>
