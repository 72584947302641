<template>
<div>
  <div class="contents_855">
    <header-bar active=""></header-bar>
    <div class="contents_box2">
      <h1 class="title2">{{ $t('notice.title') }}</h1>
      <div class="contents_box">
        <div class="form_notes_left">
          <p class="item2">{{ $t('notice.sections.pickupDelivery') }}</p>
          <ul class="list mg10">
            <li><p>{{ $t('notice.washRequest.additionalPurchase') }}</p></li>
            <li><p>{{ $t('notice.washRequest.deliveryBoxPassword') }}</p></li>
            <li><p>{{ $t('notice.washRequest.confirmationEmail') }}</p></li>
            <li><p>{{ $t('notice.washRequest.changeTimeLimit') }}</p></li>
            <li><p>{{ $t('notice.washRequest.onlyDelayAllowed') }}</p></li>
            <li><p>{{ $t('notice.washRequest.minDeliveryTime') }}</p></li>
            <li><p>{{ $t('notice.washRequest.sameDayLimit') }}</p></li>
            <li><p>{{ $t('notice.washRequest.timeWindow') }}</p></li>
            <li><p>{{ $t('notice.washRequest.absenceReschedule') }}</p></li>
            <li><p>{{ $t('notice.washRequest.autoCancel') }}</p></li>
          </ul>
        </div>
        <div class="form_notes_left">
          <p class="item2">{{ $t('notice.sections.laundryCare') }}</p>
          <ul class="list mg10">
            <li><p>{{ $t('notice.laundryCare.shrinkageWarning') }}</p></li>
            <li><p>{{ $t('notice.laundryCare.consent') }}</p></li>
            <li><p>{{ $t('notice.laundryCare.noResponsibility') }}</p></li>
            <li><p>{{ $t('notice.laundryCare.prohibitedItems') }}</p></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'notice',
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
</style>
