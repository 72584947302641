<template>
  <tr>
    <th><p class="item2">{{itemLabel}}<span class="font_orange">*</span></p></th>
    <td>
      <template v-if="useCopyFromBillingAddress">
        <p>
          <a
            class="copy-from-billing-address form_address_l radius4"
            @click="copyFromBillingAddress"
          >
            {{ $t('address.sameAsCompany') }}
          </a>
        </p>
        <p class="font_orange">{{ $t('address.differentDeliveryNote') }}</p>
        <br />
      </template>

      <p class="font_blue mg_bt5">{{ $t('address.zipcode') }}<span class="font_orange">{{ $t('address.zipcodeNote') }}</span></p>
      <p>
        <input
          type="text"
          size="10"
          class="form_basic fl_left"
          name="postal-code"
          autocomplete="postal-code"
          v-model="userState[userZipcodePropName]"
          :class="{error_outline: userErrorState.errors[userZipcodePropName]}"
        />
        <a
          class="zipcode-search form_address radius4"
          @click="doZipcodeSearch"
          :class="{disabled: !isZipcodeValid}"
        >
          {{ $t('address.searchAddress') }}
        </a>
        <span
          class="biblk ml8 font_orange zipcode-search-err-msg"
          v-if="isZipcodeSearchError"
        >
          {{ $t('address.zipcodeNotFound') }}
        </span>
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors[`${userZipcodePropName}_required`]">
          ▲ {{ $t('required') }}
        </span>
        <span class="iferror" v-show="userErrorState.errors[`${userZipcodePropName}_invalid`]">
          {{ $t('address.invalidZipcode') }}
        </span>
        <span class="iferror" v-show="userErrorState.errors[`${userZipcodePropName}_notarea`]">
          {{ $t('address.areaUnavailable') }}
        </span>
      </p>
      <p class="font_blue pd10 clear_b">{{ $t('address.prefecture') }}</p>
      <p>
        <input
          type="text"
          class="form_basic form_100p"
          name="address-level1"
          autocomplete="address-level1"
          v-model="userState[userAddress1PropName]"
          :class="{error_outline: userErrorState.errors[userAddress1PropName]}"
        />
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors[`${userAddress1PropName}_required`]">
          ▲ {{ $t('required') }}
        </span>
      </p>
      <p class="font_blue pd10 clear_b">{{ $t('address.streetAddress') }}</p>
      <p>
        <input
          type="text"
          class="form_basic form_70p"
          name="address-line1"
          autocomplete="address-line1"
          v-model="userState[userAddress2PropName]"
          :class="{error_outline: userErrorState.errors[userAddress2PropName]}"
        />
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors[`${userAddress2PropName}_required`]">
          ▲ {{ $t('required') }}
        </span>
      </p>
      <p class="font_blue pd10 clear_b">{{ $t('address.buildingAndRoom') }}</p>
      <p>
        <input
          type="text"
          class="form_basic form_70p"
          name="address-line2"
          autocomplete="address-line2"
          v-model="userState[userAddress3PropName]"
        />
      </p>
    </td>
  </tr>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useUserErrorState } from '@/components/lib/user/composables/useUserErrorState'
import masterApi from '@/api/master'

export default defineComponent({
  props: {
    itemLabel: {
      type: String,
      required: true,
    },
    itemNamePrefix: {
      type: String,
      required: true,
      validator(value) {
        return ['bill', 'send'].includes(value)
      },
    },
    useCopyFromBillingAddress: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { userState } = useUserState()
    const { userErrorState } = useUserErrorState()

    const isZipcodeSearchError = ref(false)

    const userZipcodePropName = computed(() => {
      return `${props.itemNamePrefix}_addr_zipcode`
    })
    const userAddress1PropName = computed(() => {
      return `${props.itemNamePrefix}_addr_detail1`
    })
    const userAddress2PropName = computed(() => {
      return `${props.itemNamePrefix}_addr_detail2`
    })
    const userAddress3PropName = computed(() => {
      return `${props.itemNamePrefix}_addr_detail3`
    })

    const zipcodeConv = computed(() => {
      const zc = userState[userZipcodePropName.value] || ''
      return zc.replace('-', '')
    })
    const isZipcodeValid = computed(() => {
      return zipcodeConv.value.length === 7 && zipcodeConv.value.match(/^\d+$/)
    })

    async function doZipcodeSearch() {
      if (!isZipcodeValid.value) { return }

      isZipcodeSearchError.value = false
      const data = await masterApi.searchZipcode(zipcodeConv.value)
      if (!data || Object.keys(data).length === 0) {
        isZipcodeSearchError.value = true
        return
      }

      userState[userAddress1PropName.value] = data.prefecture_name + data.address1 + data.address2 + data.address3
      userState[userAddress2PropName.value] = ''
      userState[userAddress3PropName.value] = ''
    }

    function copyFromBillingAddress() {
      userState[userZipcodePropName.value] = userState.bill_addr_zipcode
      userState[userAddress1PropName.value] = userState.bill_addr_detail1
      userState[userAddress2PropName.value] = userState.bill_addr_detail2
      userState[userAddress3PropName.value] = userState.bill_addr_detail3
    }

    return {
      userState,
      userErrorState,
      // refs
      isZipcodeSearchError,
      // computed
      zipcodeConv,
      isZipcodeValid,
      userZipcodePropName,
      userAddress1PropName,
      userAddress2PropName,
      userAddress3PropName,
      // methods
      doZipcodeSearch,
      copyFromBillingAddress,
    }
  }
})
</script>

<style lang="scss" scoped>
.zipcode-search,
.copy-from-billing-address {
  cursor: pointer;
}
.zipcode-search-err-msg {
  line-height: 2.5;
}

.disabled {
  filter: alpha(opacity=5);
  -moz-opacity:0.5;
  opacity:0.5;
}
</style>
