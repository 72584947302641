<template>
<div class="footer-box" v-if="!hideFooter">
  <ul class="glnavi_foot pc_none">
    <li>
      <a href='/wash-request' class="navi_btn">
        {{ $t('deliveryRequest') }}
      </a>
    </li>
    <li>
      <router-link :to="{name:'wash-request-currents'}" class="navi_btn">
        {{ $t('changeOrCancel') }}
      </router-link>
    </li>
    <li>
      <router-link :to="{name:'wash-history'}" class="navi_btn">
        {{ $t('usageHistory') }}
      </router-link>
    </li>
    <li>
      <router-link :to="{name:'profile'}" class="navi_btn">
        {{ $t('customerInfo') }}
      </router-link>
    </li>
    <li>
      <router-link :to="{name:'plan'}" class="navi_btn">
        {{ $t('changePlan') }}
      </router-link>
    </li>
    <li>
      <router-link :to="{name:'contact'}" class="navi_btn">
        {{ $t('contact.title') }}
      </router-link>
    </li>
    <li>
      <router-link :to="{name:'notice'}" class="navi_btn">
        {{ $t('deliveryNotes') }}
      </router-link>
    </li>
    <li>
      <a href="https://awesome-wash.com" target="_blank"
        class="navi_btn">{{ $t('footer.menu.home') }}</a>
    </li>
  </ul>

  <footer class="foot_wrap">
    <div class="contents_wrap">
      <div class="foot_sitemap tab_none sp_none">｜　<a href="https://awesome-wash.com/" target="_blank" class="link_gray">{{ $t('footer.menu.home') }}</a>　｜　<a href="https://awesome-wash.com/company/" target="_blank" class="link_gray">{{ $t('footer.menu.company') }}</a>　｜　<a href="https://awesome-wash.com/agreement/" target="_blank" class="link_gray">{{ $t('footer.menu.terms') }}</a>　｜　<a href="https://awesome-wash.com/privacypolicy/" target="_blank" class="link_gray">{{ $t('footer.menu.privacy') }}</a>　｜　<router-link :to="{name:'contact'}" class="link_gray">{{ $t('contact.title') }}</router-link>　｜</div>
      <p class="foot_logo"><a href="https://awesome-wash.com"><img src="/static/images/logo_hd.gif" alt="AWESOME WASH"></a></p>

      <p class="foot_info pc_br_none"><span class="bold">{{ $t('footer.companyInfo.serviceName') }}</span>　<br>{{ $t('footer.companyInfo.companyName') }}　<br>{{ $t('footer.companyInfo.address') }} <span class="pc_br_none tab_br_none sp_br_yes"><br></span>{{ $t('footer.companyInfo.tel') }}</p>
      </div>
    <p class="copyright">{{ $t('footer.copyright') }}</p>
    <transition name="fade">
    <div class="pagetop" v-show="showPageTopButton">
      <a @click="scrollToTop()"><br>▲<br>{{ $t('footer.pageTop') }}</a>
    </div>
    </transition>
  </footer>

</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import routeMixin from '@/mixin/routeMixin'

export default {
  name: 'global-footer',
  data() {
    return {
      scrollTop: document.documentElement.scrollTop || document.body.scrollTop,
      scrollHandler: null,
    }
  },
  computed: {
    ...mapGetters('user', [
      'isLoggedIn'
    ]),
    showPageTopButton() {
      return this.scrollTop > 700
    },
    hideFooter() {
      return !this.isLoggedIn || this.isGuestPage() || this.isAdminPage() || this.isStaffPage()
    },
  },
  mixins: [routeMixin],
  methods: {
    handleScroll() {
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    },
    scrollToTop() {
      // 仕方なくjquery
      $('body, html').animate({ scrollTop: 0 }, 500);
    },
  },
  mounted() {
    this.scrollHandler = () => {
      this.handleScroll()
    }
    window.addEventListener('scroll', this.scrollHandler)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler)
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-active {
  opacity: 0;
}
</style>
