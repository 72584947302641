<template>
<div>
  <no-login-header></no-login-header>

  <h1 class="pagetitle">{{ $t('passwordReset.title') }}</h1>

  <div v-show="isStep1">
    <div class="contents_login">
      <div class="contents_box">
        <p class="left mg10" v-html="$t('passwordReset.description')">
        </p>

        <p class="item">{{ $t('passwordReset.email') }}</p>
        <input type="text" size="40" v-model="params.email"
          class="text verifyText form_basic form_100p" />

        <div class="err-msg-area">
          <p><span class="font_orange" v-for="e in serverErrors">
            {{e}}
          </span></p>
        </div>

        <p class="submit_bx1">
          <button @click="forgotPassword()" class="submit1 bg_blue"
              :class="{disabled: !params.email}">
            {{ $t('passwordReset.submit') }}
          </button>
        </p>
      </div>
    </div>
  </div>

  <div v-show="isStep2">
    <div class="contents_notes">
      <p class="font_orange mg_bt80" v-html="$t('passwordReset.confirmationSent')">
      </p>
    </div>
  </div>

  <no-login-footer></no-login-footer>
</div>
</template>

<script>
import userApi from '@/api/user'

export default {
  name: 'password-forgot',
  data() {
    return {
      currentStep: 1,
      maxStep: 2,
      isStep1: true,
      isStep2: false,

      params: {
        email: '',
      },

      serverErrors: [],
    }
  },
  methods: {
    nextStep() {
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    forgotPassword() {
      if (!this.params.email) { return }

      this.serverErrors = []

      userApi.forgotMyPassword(this.params)
        .then(({ data }) => {
          this.nextStep()
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.serverErrors = [
              this.$t('passwordReset.errors.userNotFound')
            ]
          } else {
            const errRes = e.response.data.errors;
            let serverErrors = []
            Object.keys(errRes).forEach(k => {
              serverErrors = serverErrors.concat(errRes[k])
            })
            this.serverErrors = serverErrors;
          }
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  filter: alpha(opacity=5);
  -moz-opacity:0.5;
  opacity:0.5;
}
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.submit_bx1 {
  margin: 12px auto;
}
</style>
