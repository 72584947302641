<template>
<ul class="list">
  <li><p>{{ $t('notice.plan.sizeChange') }}</p></li>
  <li><p>{{ $t('notice.plan.pickupMethodChange') }}</p></li>
  <li><p>{{ $t('notice.plan.paymentMethodChange') }}</p></li>
</ul>
</template>

<script>
export default {
  name: 'edit-plan-notice-messages',
  data() {
    return {}
  },
}
</script>
