<template>
<div class="contents_855">
  <header-bar active="plan"></header-bar>

  <h1 class="title2">{{ $t('changePlan') }}</h1>

  <div class="contents_box">
    <table class="form_tab form_line_top">
      <tr>
        <th><p class="item2">{{ $t('plan.plan') }}</p></th>
        <td><p>{{currentPlan.mst_name}}</p></td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('plan.pickupMethod') }}</p></th>
        <td><p>{{pickupDeliveryTypeDisp}}</p></td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('plan.monthlyCharge') }}</p></th>
        <td>
          <p v-if="isOnetime">{{ $t('plan.payPerUse') }}</p>
          <p v-else>{{currentPlan.mst_price | currency('',0)}}{{ $t('washRequestOnetime.form.priceUnit') }}</p>
        </td>
      </tr>
    </table>
    <p class="submit_bx1">
      <router-link :to="{name:'plan-edit'}" class="submit1 bg_green">
        {{ $t('changePlan') }}
      </router-link>
    </p>
  </div>

  <div class="contents_box2 mg40">
    <h1 class="title2">{{ $t('plan.withdrawal') }}</h1>
  </div>
  <div class="contents_box" v-if="!withdrawal_dt">
    <p class="form_notes">{{ $t('plan.withdrawalNotes') }}</p>
    <div class="form_notes_left">
      <withdrawal-notice-messages :planPart1="planPart1"></withdrawal-notice-messages>
    </div>
    <hr class="hr_blue">
    <p class="submit_bx1">
      <router-link :to="{name:'withdrawal'}" v-bind:class="`submit1 bg_green ${firstWithdrawalClass}`"
          :event="!isFirstMonth ? 'click' : ''">
        {{ $t('plan.withdraw') }}
      </router-link>
    </p>
  </div>
  <div class="contents_box" v-else>
    <p class="form_notes">
      {{ $t('plan.withdrawalRequested') }}
    </p>
    <p class="form_notes">
      <span>{{ $t('plan.withdrawalDate') }}: {{withdrawal_dt | dtFormat($t('dateTimeFormat.date')) }}</span>
    </p>
  </div>
</div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import userApi from '@/api/user'

export default {
  name: 'plan',
  data() {
    return {
      isFirstMonth: false,
      firstWithdrawalClass: '',
      pdTypeMap: {},
      optionMagMap: {},
      plans: [],
      planMap: {},
      currentPlan: {},
      isOnetime: false,
      planPart1: '',
    }
  },
  computed: {
    ...mapState('user', {
      withdrawal_dt: state => state.withdrawal_dt,
    }),
    pickupDeliveryTypeDisp() {
      const obj = this.pdTypeMap[this.currentPlan.pickup_delivery_type]
      return obj ? obj.val : ''
    },
  },
  mounted() {
    const token = this.$store.getters['localStorage/serverToken']
    if (!token) {
      this.$router.push({name: 'login'})
    }

    this.$store.dispatch('user/getMe').then(me => {
      const dtFormat = Vue.filter('dtFormat')
      const uStart = dtFormat(me.created_at, 'yyyymm')
      const now = dtFormat(new Date(), 'yyyymm')
      if (now === uStart) {
        this.isFirstMonth = true
        this.firstWithdrawalClass = 'disabled_btn'
      }
    })

    window.master.$promise.then(mst => {
      this.pdTypeMap = mst.lovsMap.pd_type
      this.optionMagMap = mst.lovsMap.user_plan_option_mag
      this.plans = mst.plans
      this.planMap = mst.planMap
      this.other = mst.other

      userApi.getMyCurrentPlan().then(({ data }) => {
        const planMst = this.planMap[data.plan_id]
        Object.keys(planMst).forEach(k => {
          if (k === 'price' && data['user_plan_option']) {
            data[`mst_${k}`] = planMst[k] * this.optionMagMap[data['user_plan_option']].val
          } else {
            data[`mst_${k}`] = planMst[k]
          }
        })
        this.currentPlan = data
        this.planPart1 = this.currentPlan.plan_id.split('_')[0]
        if (this.planPart1 == 'tx') {
          this.isOnetime = true
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.disabled_btn {
  opacity: 0.6;
}
</style>
