<template>
<div class="contents_855">
  <header-bar active="plan"></header-bar>

  <div>
    <h1 class="title2">{{ $t('plan.changeComplete') }}</h1>
    <div class="contents_box mg20">
      <p class="mypage_notes mg30 mg_bt30 font_orange">
        {{ $t('plan.changeCompleted') }}
      </p>
      <p class="mypage_notes mg40">
        {{ $t('plan.emailSent') }}
      </p>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: 'plan-edit-complete',
}
</script>
