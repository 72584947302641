<template>
<div class="contents_855">
  <header-bar active="profile"></header-bar>

  <div>
    <h1 class="title2">{{ $t('profile.customerInfo') }}</h1>
    <ReadonlyViewIndividual
      v-if="!isCompany"
      :show-payment-method="false"
    />
    <ReadonlyViewCompany
      v-else
      :show-payment-method="false"
    />
    <div class="contents_box mg20">
      <table class="form_tab">
        <tr>
          <th><p class="item2">{{ $t('profile.invitationCode') }}</p></th>
          <td><p>{{invitationCode}}</p></td>
        </tr>
      </table>
      <p class="submit_bx_l">
        <router-link :to="{name:'profile-edit'}" class="submit1 bg_green">
          {{ $t('profile.editInfo') }}
        </router-link>
      </p>
      <p class="submit_bx_r">
        <router-link :to="{name:'password-change'}" class="submit1 bg_green">
          {{ $t('profile.changePassword') }}
        </router-link>
      </p>
    </div>
  </div>
</div>
</template>

<script>
import { onMounted, ref, computed, defineComponent, getCurrentInstance } from 'vue'
import { provideFilledUserState } from '@/components/lib/user/composables/useUserState'
import ReadonlyViewCompany from '@/components/lib/user/components/ReadonlyViewCompany.vue'
import ReadonlyViewIndividual from '@/components/lib/user/components/ReadonlyViewIndividual.vue'
import { provideMaster } from '@/composables/useMaster'

export default defineComponent({
  components: { ReadonlyViewIndividual, ReadonlyViewCompany },
  setup() {
    const vue = getCurrentInstance().proxy
    provideMaster()
    const userState = provideFilledUserState()

    const invitationCode = ref('');
    const isCompany = computed(() => userState.is_company)

    onMounted(async() => {
      const token = vue.$store.getters['localStorage/serverToken']
      if (!token) {
        await vue.$router.push({name: 'login'})
        return
      }

      const me = await vue.$store.dispatch('user/getMe')
      invitationCode.value = me.invitation_code
    })

    return {
      invitationCode,
      isCompany,
    }
  },
  computed: {


  },
})
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 0;
}
</style>
