<template>
<div>
  <div class="contents_box" v-show="isStep1">
    <table class="form_tab form_line_top">
      <tr>
        <th><p class="item2">{{ $t('washRequest.plan') }}</p></th>
        <td>
          <p v-show="!!currentPlan.mst_name" class="plan_name_wrap">
            <span class="plan_name">{{currentPlan.mst_name}}</span>
            <router-link :to="{name:'buy-wash-request'}"
                class="btn btn_add_credit radius4 bg_blue"
            >{{ $t('washRequest.addPurchase') }}</router-link>
          </p>
            <p class="font_orange" v-html="$t('washRequest.remainingRequests', { currentMonth, availableRequestCount })"></p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('washRequest.quantity') }}</p></th>
        <td>
          <p>
            <select v-model="count" class="count">
              <option v-for="elem in counts" v-bind:value="elem">
                {{elem}}{{ $t('washRequest.unit') }}
              </option>
            </select>
          </p>
        </td>
      </tr>
      <tr>
        <th class="form_line_bt"><p class="item2">{{ $t('washRequest.pickupDeliveryTime') }}</p></th>
        <td class="form_line_bt">
          <p class="pc_br_none tab_br_none sp_br_yes">{{ $t('washRequest.pickup') }}：<br>
            <select v-model="pickupMonth" @change="setPickupDates()" class="w55">
              <option v-for="elem in months" v-bind:value="elem">
                {{elem}}
              </option>
            </select>{{ $t('date.month') }}
            <select v-model="pickupDate" class="w55">
              <option v-for="elem in pickupDates" v-bind:value="elem">
                {{elem}}
              </option>
            </select>{{ $t('date.day') }}
            <select v-show="!areaInfoSameTime" v-model="pickupHour" @change="setPickupMinutes()" class="w55">
              <option v-for="elem in convHours" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
            <span class="ml20" v-show="areaInfoSameTime">{{sHour}}</span>
            ：
            <select  v-show="!areaInfoSameTime" v-model="pickupMinute" class="w55">
              <option v-for="elem in pickupMinutes" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
            <span v-show="areaInfoSameTime">{{sMinute}}</span>
          </p>

          <p class="mg10 pc_br_none tab_br_none sp_br_yes">{{ $t('washRequest.delivery') }}：<br>
            <span v-if="pdSameTime">
              <span class="font_orange">{{ $t('washRequest.deliverOnNextPickup') }}</span>
            </span>
            <span v-else>
              <select v-model="deliveryMonth" @change="setDeliveryDates()" class="w55">
                <option v-for="elem in months" v-bind:value="elem">
                  {{elem}}
                </option>
              </select>{{ $t('date.month') }}
              <select v-model="deliveryDate" class="w55">
                <option v-for="elem in deliveryDates" v-bind:value="elem">
                  {{elem}}
                </option>
              </select>{{ $t('date.day') }}
              <select v-show="!areaInfoSameTime" v-model="deliveryHour" @change="setDeliveryMinutes()" class="w55">
                <option v-for="elem in convHours" v-bind:value="elem.val">
                  {{elem.disp}}
                </option>
              </select>
              <span class="ml20" v-show="areaInfoSameTime">{{sHour}}</span>
              ：
              <select v-show="!areaInfoSameTime" v-model="deliveryMinute" class="w55">
                <option v-for="elem in deliveryMinutes" v-bind:value="elem.val">
                  {{elem.disp}}
                </option>
              </select>
              <span v-show="areaInfoSameTime">{{sMinute}}</span>
            </span>
          </p>
          <p class="font_orange mg10">{{ $t('washRequest.timeWindowNote') }}</p>
          <span class="font_orange">{{areaInfo.bikou}}</span>
        </td>
      </tr>

      <tr>
        <th>
          <p class="item2">{{ $t('washRequest.deliveryBoxPassword') }}</p>
        </th>
        <td>
          <p>
            <input type="text" v-model="deliveryBoxPw"
              class="form_basic">
          </p>
          <p class="font_orange mg10">{{ $t('washRequest.deliveryBoxNote') }}</p>
        </td>
      </tr>

      <tr>
        <th><p class="item2">{{ $t('washRequest.remarks') }}</p></th>
        <td>
          <p>
            <textarea type="text" name="message" id="message"
              v-model="bikou" maxlength="500"
              size="20" class="form_basic"
            >
            </textarea>
          </p>
        </td>
      </tr>

      <tr>
        <th class="form_line_l"></th>
        <td class="form_line_r">
          <wash-request-notice-messages mongon-type="A">
          </wash-request-notice-messages>
        </td>
      </tr>
    </table>
    <div class="err-msg-area">
      <span class="font_orange" v-show="noAvailableRequestCount">
        {{ $t('washRequest.errors.noRemainingRequests') }}
      </span>
      <span class="font_orange" v-show="showErrMsg1">
        {{ $t('washRequest.errors.invalidDate') }}
      </span>
      <span class="font_orange" v-show="showErrMsg2">
        {{ $t('washRequest.errors.pickupTimeTooEarly') }}
      </span>
      <span class="font_orange" v-show="showErrMsg3">
        {{ $t('washRequest.errors.deliveryTimeTooEarly') }}
      </span>
      <span class="font_orange" v-show="showErrMsg4">
        {{ $t('washRequest.errors.deliveryTimeLimit') }}
      </span>
      <span class="font_orange" v-show="showErrMsg5">
        {{ $t('washRequest.errors.sameTimePlanLimit') }}
      </span>
      <span class="font_orange" v-show="showErrMsg6">
        {{ $t('washRequest.errors.areaUnavailable') }}
      </span>
      <span class="font_orange" v-show="showErrMsg7">
        {{ $t('washRequest.errors.pickupTime7HourLimit') }}
      </span>
    </div>
    <p class="submit_bx1">
      <button @click="nextStep()" class="submit1 bg_green step1do"
          :disabled="noAvailableRequestCount || role == 200">
        {{ $t('confirmButton') }}
      </button>
    </p>
  </div>

  <div class="contents_box" v-show="isStep2">
    <p class="mypage_notes mg30 mg_bt30 font_orange">
      {{ $t('washRequest.confirmMessage') }}
    </p>
    <table class="form_tab form_line_top">
      <tr>
        <th><p class="item2">{{ $t('washRequest.quantity') }}</p></th>
        <td><p>{{count}}{{ $t('washRequest.unit') }}</p></td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('washRequest.pickupDateTime') }}</p></th>
        <td><p>{{pickupDt | dtFormat($t('dateTimeFormat.shortDateTimeWithWeekday'))}}</p></td>
      </tr>
      <tr>
        <th class="form_line_l"><p class="item2">{{ $t('washRequest.deliveryDateTime') }}</p></th>
        <td class="form_line_r">
          <p v-if="pdSameTime" class="font_orange">{{ $t('washRequest.deliverOnNextPickup') }}</p>
          <p v-else>{{deliveryDt | dtFormat($t('dateTimeFormat.shortDateTimeWithWeekday'))}}</p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('washRequest.deliveryBoxPassword') }}</p></th>
        <td><p>{{deliveryBoxPw}}</p></td>
      </tr>
      <tr>
        <th><p class="item2">{{ $t('washRequest.remarks') }}</p></th>
        <td><p v-html="$options.filters.lineBreakToBR(bikou)"></p></td>
      </tr>
    </table>
    <div class="err-msg-area">
      <p><span class="font_orange" v-show="noAvailableRequestCount">
        {{ $t('washRequest.errors.noRemainingRequests') }}
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg1">
        {{ $t('washRequest.errors.invalidDate') }}
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg2">
        {{ $t('washRequest.errors.pickupTimeTooEarly') }}
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg3">
        {{ $t('washRequest.errors.deliveryTimeTooEarly') }}
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg4">
        {{ $t('washRequest.errors.deliveryTimeLimit') }}
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg5">
        {{ $t('washRequest.errors.sameTimePlanLimit') }}
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg6">
        {{ $t('washRequest.errors.areaUnavailable') }}
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg7">
        {{ $t('washRequest.errors.pickupTime7HourLimit') }}
      </span></p>
      <p><span class="font_orange" v-show="isDupRequest">
        {{ $t('washRequest.errors.duplicateRequest') }}
      </span></p>
      <p><span class="font_orange" v-show="isStatusDisabled">
        {{ $t('washRequest.errors.paymentDisabled') }}
      </span></p>
      <p><span class="font_orange" v-show="isStatusWithdrawn">
        {{ $t('accountWithdrawn') }}
      </span></p>
      <p><span class="font_orange" v-show="isStatusStoppedCredit">
        {{ $t('washRequest.errors.creditCardStopped') }}
      </span></p>
      <p><span class="font_orange" v-show="isStatusStoppedOther">
        {{ $t('washRequest.errors.paymentStopped') }}
      </span></p>
      <p><span class="font_orange" v-show="isWreqCountLacking">
        {{ $t('washRequest.errors.insufficientRequests') }}
      </span></p>
      <p><span class="font_orange" v-show="isPreqCountMax">
        {{ $t('washRequest.errors.maxRequestsReached') }}
      </span></p>
    </div>
    <p class="submit_bx_l">
      <button @click="prevStep()" class="submit1 bg_gray">
        {{ $t('backButton') }}
      </button>
    </p>
    <p class="submit_bx_r">
      <button @click="addWashRequest()" class="submit1 bg_green">
        {{ $t('okButton') }}
      </button>
    </p>
  </div>

  <div class="contents_box" v-show="isStep3">
    <p class="mypage_notes mg40 mg_bt40 font_orange" v-html="$t('washRequest.requestComplete')">
    </p>
    <p class="mypage_notes mg40">
      {{ $t('washRequest.emailConfirmation') }}<br>
      <span v-html="$t('washRequest.checkDeliveryNotes')"></span>
    </p>
    <p class="submit_bx1">
      <router-link :to="{name:'wash-request-currents'}" class="glnavi_btn_on">
        {{ $t('washRequest.checkDetails') }}
      </router-link>
    </p>
    <p class="mypage_notes mg40">
      <a href="https://awesome-wash.com/friend/" target="_blank">
        <picture>
          <source media="(min-width: 641px)" srcset="https://awesome-wash.com/wp-content/themes/aw_themes/images/bn_friand.jpg">
          <source media="(max-width: 640px)" srcset="https://awesome-wash.com/wp-content/themes/aw_themes/images/bn_friand_sp.jpg">
          <img src="https://awesome-wash.com/wp-content/themes/aw_themes/images/bn_friand.jpg" :alt="$t('washRequest.friendBannerAlt')" style="width:100%">
        </picture>
      </a>
    </p>
  </div>
</div>
</template>

<script>
import Vue from 'vue'
import userApi from '@/api/user'
import areaInfoApi from '@/api/areaInfo'
import dateMixin from '@/mixin/dateMixin'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'wash-request',
  data() {
    return {
      currentStep: 1,
      maxStep: 3,
      isStep1: true,
      isStep2: false,
      isStep3: false,

      currentPlan: {},
      pdSameTime: false,
      months: window.VueMonthOptions,
      dates: window.VueDateOptions,
      hours: window.VueHourOptions,
      minutes: this.setMinuteOption([0,30]),
      minutes2: this.setMinuteOption([0]),
      pickupDates: [],
      deliveryDates: [],
      convHours: window.VueHourOptions,
      pickupMinutes: this.setMinuteOption([0,30]),
      deliveryMinutes: this.setMinuteOption([0,30]),
      areaInfo: [],
      sHour: 10,
      sMinute: 0,
      eHour: 10,
      eMinute: 0,
      areaInfoSameTime: false,

      counts: [
        1,2,3,4,5
      ],
      pickupYear: 9999,
      pickupMonth: 1,
      pickupDate: 1,
      pickupHour: 12,
      pickupMinute: 0,
      pickupDt: null,
      deliveryYear: 9999,
      deliveryMonth: 1,
      deliveryDate: 1,
      deliveryHour: 12,
      deliveryMinute: 0,
      deliveryDt: null,
      count: 1,
      deliveryBoxPw: '',
      // Business Rule No.20 備考欄は最大で全角500文字まで入力可能
      bikou: '',
      businessHolidays: [],

      showErrMsg1: false,
      showErrMsg2: false,
      showErrMsg3: false,
      showErrMsg4: false,
      showErrMsg5: false,
      showErrMsg6: false,
      showErrMsg7: false,

      isWaitingResponse: false,
      isDupRequest: false,
      isStatusDisabled: false,
      isStatusWithdrawn: false,
      isStatusStoppedCredit: false,
      isStatusStoppedOther: false,
      isWreqCountLacking: false,
      isPreqCountMax: false,
      zipcode: '',
    }
  },
  computed: {
    ...mapState('user', {
      // Business Rule No.17 x月はあとy個使えます
      availableRequestCount: state => state.available_request_count,
      isSaturdayAvailable: state => state.is_saturday_available,
      role: state => state.role,
      sendAddrZipcode: state => state.send_addr_zipcode,
    }),
    currentMonth() {
      return (new Date()).getMonth() + 1
    },
    noAvailableRequestCount() {
      return this.availableRequestCount <= 0
    },
  },
  async mounted() {
    // 休業日取得
    this.businessHolidays = await this.getBusinessHolidays()
    this.zipcode = this.sendAddrZipcode
    if (!this.zipcode) {
      await this.getMe()
    }
    const obj = {
      'zipcode': this.zipcode,
      'is_onetime': 0
    }

    areaInfoApi.getAreaInfo(obj).then(({ data }) => {
      this.areaInfo = data

      if (!data['error']) {
        let sTimes = this.areaInfo.start_time.split(':')
        this.sHour = sTimes[0]
        this.sMinute = sTimes[1] == 0 ? 0 : sTimes[1]
        let eTimes = this.areaInfo.end_time.split(':')
        this.eHour = eTimes[0]
        this.eMinute = eTimes[1] == 0 ? 0 : eTimes[1]
        // 15:00~22:00のエリアの場合のみ、選択開始時間を1時間遅らせる
        if (sTimes[0] == 15 && sTimes[1] == 0 && eTimes[0] == 22 && eTimes[1] == 0) {
          this.sHour = Number(sTimes[0]) + 1
        }
      } else {
        this.sMinute = "00"
      }

      if (this.sHour ==this.eHour && this.sMinute == this.eMinute) {
        this.areaInfoSameTime = true
        if (this.sMinute == 0) {
          this.sMinute = "00"
        }
      }

      this.convHours = this.convHours.filter(v => v.val >= this.sHour && v.val <= this.eHour)

      // Business Rule No.2 集荷時間のデフォルトは現在プラス5時間
      let pickupDt = new Date(new Date().valueOf() + 3600*5*1000)
      // 分に余りがあったら時間を進める
      if (pickupDt.getMinutes() > 0) {
        pickupDt = new Date(pickupDt.valueOf() + 3600*1*1000)
      }

      // プラスした時間が当日中最終選択時間よりあとの場合はデフォルトの時間を翌日にするために更に時間をプラスする
      if (pickupDt.getHours() > this.eHour || (pickupDt.getHours() == this.eHour && pickupDt.getMinutes() > this.eMinute)) {
        let to24 = 24 - pickupDt.getHours()
        pickupDt = new Date(pickupDt.valueOf() + 3600*to24*1000)
      }

      // 選べる時間が18時以降とかなので、それより前だったらそこまで進める
      // →エリア情報の選択可能な時間で比較する
      const pickupHour = pickupDt.getHours()
      const hourDiff = this.sHour - pickupHour
      if (hourDiff > 0) {
        pickupDt = new Date(pickupDt.valueOf() + 3600*hourDiff*1000)
      }

      pickupDt = this.getAvailableDate(pickupDt, this.businessHolidays, this.isSaturdayAvailable)  // 休業日考慮
      this.pickupYear = pickupDt.getFullYear()
      this.pickupMonth = pickupDt.getMonth() + 1
      this.pickupDate = pickupDt.getDate()
      this.pickupHour = pickupDt.getHours()
      if (pickupDt.getHours() > this.convHours[this.convHours.length -1].val) {
        this.pickupHour = this.convHours[this.convHours.length -1].val
      }
      this.pickupMinute = this.sMinute // ユーザーが混乱しそうだから00分でよいだろう→0が選択できない可能性もあるため開始の分にする

      // Business Rule No.3 配達時間のデフォルトは集荷時間の48時間後に設定
      let deliveryDt = new Date(pickupDt.valueOf() + 86400*2*1000)
      deliveryDt = this.getAvailableDate(deliveryDt, this.businessHolidays)  // 休業日考慮
      // 期間内で休日を除き48時間以上の時間があるか確認
      const workingDay = this.getWorkingDay(pickupDt, deliveryDt, this.businessHolidays, this.isSaturdayAvailable)
      if (workingDay < 3) {
        // 営業日数が２日未満の場合
        const addDate = 3 - workingDay
        deliveryDt.setDate((deliveryDt.getDate() + addDate))
      }
      this.deliveryYear = deliveryDt.getFullYear()
      this.deliveryMonth = deliveryDt.getMonth() + 1
      this.deliveryDate =  deliveryDt.getDate()
      this.deliveryHour =  deliveryDt.getHours()
      if (deliveryDt.getHours() > this.convHours[this.convHours.length -1].val) {
        this.deliveryHour = this.convHours[this.convHours.length -1].val
      }
      this.deliveryMinute = this.sMinute  // ユーザーが混乱しそうだから00分でよいだろう→0が選択できない可能性もあるため開始の分にする
      this.setPickupDates()
      this.setDeliveryDates()
      this.setPickupMinutes()
      this.setDeliveryMinutes()
    })

    window.master.$promise.then(mst => {
      userApi.getMyCurrentPlan().then(({ data }) => {
        const planMst = mst.planMap[data.plan_id]
        Object.keys(planMst).forEach(k => {
          data[`mst_${k}`] = planMst[k]
        })
        // Business Rule No.16 ご利用プラン：契約中のプランが表示される
        this.currentPlan = data

        const planInfos = data.plan_id.split('_')
        if (planInfos.length > 2 && planInfos[2] == 1) {
          this.pdSameTime = true
        } else {
          this.pdSameTime = false
        }
      })
    })
  },
  mixins: [dateMixin],
  methods: {
    setMinuteOption(key) {
      if (!key) {
        return window.VueMinuteOptions
      }
      let filteredOptions = window.VueMinuteOptions.filter(option => key.includes(option.val));
      return filteredOptions
    },
    async getMe() {
      await this.$store.dispatch('user/getMe')
        .then(me => {
        this.zipcode = me.send_addr_zipcode
      })
    },
    nextStep() {
      if (!this.checkCurrentStep()) { return }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    setPickupDates() {
      // 対象年月から休業日を除く日付を取得
      const yearAdjust = this.isNextYear(this.pickupYear, this.pickupMonth) ? 1 : 0
      this.pickupDates = this.getWeekDaysList(this.pickupYear + yearAdjust, this.pickupMonth, this.businessHolidays, this.isSaturdayAvailable)
      if (this.pickupDates.indexOf(this.pickupDate) === -1) {
        const pickupDt = new Date(
          this.pickupYear + yearAdjust, this.pickupMonth - 1, this.pickupDates[0],
          this.pickupHour, this.pickupMinute, 0)
        this.pickupDt = this.getAvailableDate(pickupDt, this.businessHolidays, this.isSaturdayAvailable)
        this.pickupDate = this.pickupDt.getDate()
      }
    },
    setDeliveryDates() {
      // 対象年月から休業日を除く日付を取得
      const yearAdjust = this.isNextYear(this.deliveryYear, this.deliveryMonth) ? 1 : 0
      this.deliveryDates = this.getWeekDaysList(this.deliveryYear + yearAdjust, this.deliveryMonth, this.businessHolidays, this.isSaturdayAvailable)
      if (this.deliveryDates.indexOf(this.deliveryDate) === -1) {
        const deliveryDt = new Date(
          this.deliveryYear + yearAdjust, this.deliveryMonth - 1, this.deliveryDates[0],
          this.deliveryHour, this.deliveryMinute, 0)
        this.deliveryDt = this.getAvailableDate(deliveryDt, this.businessHolidays, this.isSaturdayAvailable)
        this.deliveryDate = this.deliveryDt.getDate()
      }
    },
    setPickupMinutes() {
      if (this.hours[this.hours.length - 1].val === this.pickupHour) {
        // 22時を選択した場合、00分まで選択可能とする
        this.pickupMinute = this.setMinute(this.pickupMinute)
        this.pickupMinutes = this.minutes2
      } else if (this.convHours[this.convHours.length - 1].val === this.pickupHour) {
        // end_timeの分が00分で、その時間が選択された場合は00分まで選択可能とする
        if (this.eMinute == 0) {
          this.pickupMinute = this.setMinute(this.pickupMinute)
          this.pickupMinutes = this.minutes2
        }
      } else {
        this.pickupMinutes = this.minutes
      }
    },
    setDeliveryMinutes() {
      if (this.hours[this.hours.length - 1].val === this.deliveryHour) {
        // 22時を選択した場合、00分まで選択可能とする
        this.deliveryMinute = this.setMinute(this.deliveryMinute)
        this.deliveryMinutes = this.minutes2
      } else if (this.convHours[this.convHours.length - 1].val === this.deliveryHour) {
        // end_timeの分が00分で、その時間が選択された場合は00分まで選択可能とする
        if (this.eMinute == 0) {
          this.deliveryMinute = this.setMinute(this.deliveryMinute)
          this.deliveryMinutes = this.minutes2
        }
      } else {
        this.deliveryMinutes = this.minutes
      }
    },
    setMinute(minute) {
      return this.minutes2[this.minutes2.length - 1].val < minute ? 0 : minute
    },
    checkStep1() {
      this.resetLocalErrMsgs()
      this.resetServerErrMsgs()

      // 提供エリア外の場合は依頼不可
      if (this.areaInfo['error']) {
        this.showErrMsg6 = true
        return false
      }

      const now = new Date()
      let yearAdjust = this.isNextYear(this.pickupYear, this.pickupMonth) ? 1 : 0
      const pickupDt = new Date(
        this.pickupYear + yearAdjust, this.pickupMonth - 1, this.pickupDate,
        this.pickupHour, this.pickupMinute, 0)
      yearAdjust = this.isNextYear(this.deliveryYear, this.deliveryMonth) ? 1 : 0
      const deliveryDt = new Date(
        this.deliveryYear + yearAdjust, this.deliveryMonth - 1, this.deliveryDate,
        this.deliveryHour, this.deliveryMinute, 0)

      if (isNaN(pickupDt.valueOf()) || isNaN(deliveryDt.valueOf())) {
        this.showErrMsg1 = true
        return false
      }

      if (this.pdSameTime) {
        // 同時集配の場合は現在より7時間後以降になければエラー
        let samePickupMinDt = new Date(now.valueOf() + 3600*7*1000)
        if (pickupDt < samePickupMinDt) {
          this.showErrMsg7 = true
          return false
        }
      } else {
        // 集配別の場合は現在より5時間後以降になければエラー
        let pickupMinDt = new Date(now.valueOf() + 3600*5*1000)
        if (pickupDt < pickupMinDt) {
          this.showErrMsg2 = true
          return false
        }
      }

      if (this.pdSameTime) {
        let nowTime = new Date()
        yearAdjust = this.isNextYear(this.pickupYear, this.pickupMonth) ? 1 : 0
        let pickupTimeLimit = new Date(
          this.pickupYear + yearAdjust, this.pickupMonth - 1, this.pickupDate, 11, 0, 0)
        if (nowTime > pickupTimeLimit) {
          // 集配同時プランで当日の集配依頼の場合、集配依頼を出せる時間を11時までとする
          this.showErrMsg5 = true
          return false
        }

        // 集配同時の場合は配達時間のチェックに引っかからないs
        deliveryDt.setFullYear(2099)
      }

      // Business Rule No.4 配達時間は最短でも集荷時間の48時間後以降しか選べない
      const minDiff = 86400*2*1000
      const result = deliveryDt.valueOf() - pickupDt.valueOf() >= minDiff
      if (!result) {
        this.showErrMsg3 = true
        return false
      }

      // かつ配達時間は休業日を除く48時間後しか依頼できない
      const workingDay = this.getWorkingDay(pickupDt, deliveryDt, this.businessHolidays, this.isSaturdayAvailable)
      if (workingDay < 3) {
        this.showErrMsg3 = true
        return false
      }

      // Business Rule No.4.1 配達時間は最長で30日後まで選択可能
      let deliveryMaxDt = new Date(now.valueOf() + 3600*24*30*1000)
      if (!this.pdSameTime) {
        // 集配同時の場合は除く
        if (deliveryDt > deliveryMaxDt) {
          this.showErrMsg4 = true
          return false
        }
      }

      this.pickupDt = pickupDt
      this.deliveryDt = deliveryDt
      return true
    },
    checkStep2() {
      return true
    },
    addWashRequest() {
      if (!this.checkStep1()) { return }
      if (this.isWaitingResponse) { return }
      // 念のため...
      if (this.availableRequestCount <= 0) { return }

      this.isWaitingResponse = true
      this.resetServerErrMsgs()

      const dtFormat = Vue.filter('dtFormat')
      const data = {
        sched_pickup_dt: dtFormat(
          this.pickupDt, 'yyyy-mm-dd HH:MM:SS'),
        sched_delivery_dt: dtFormat(
          this.deliveryDt, 'yyyy-mm-dd HH:MM:SS'),
        count: this.count,
        delivery_box_pw: this.deliveryBoxPw,
        bikou: this.bikou,
      }
      userApi.addWashRequest(data).then(({ data }) => {
        this.isWaitingResponse = false
        this.nextStep()
        this.$store.dispatch(
          'user/getMe',
          {forceRefresh: true}
        )
      }).catch(err => {
        this.isWaitingResponse = false
        if (err.response.data.reason === 'dup') {
          this.isDupRequest = true
        }
        if (err.response.data.reason === 'status_disabled') {
          this.isStatusDisabled = true
        }
        if (err.response.data.reason === 'status_withdrawn') {
          this.isStatusWithdrawn = true
          this.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: null,
          })
        }
        if (err.response.data.reason === 'status_stopped_credit') {
          this.isStatusStoppedCredit = true
        }
        if (err.response.data.reason === 'status_stopped_other') {
          this.isStatusStoppedOther = true
        }
        if (err.response.data.reason === 'wreq_count_lacking') {
          this.isWreqCountLacking = true
        }
        if (err.response.data.reason === 'preq_count_max') {
          this.isPreqCountMax = true
        }
        if (err.response.data.reason === 'pickup_time_limit') {
          if (this.pdSameTime) {
            this.showErrMsg7 = true
          } else {
            this.showErrMsg2 = true
          }
        }
      })
    },
    resetLocalErrMsgs() {
      this.showErrMsg1 = false
      this.showErrMsg2 = false
      this.showErrMsg3 = false
      this.showErrMsg4 = false
      this.showErrMsg5 = false
      this.showErrMsg6 = false
      this.showErrMsg7 = false
    },
    resetServerErrMsgs() {
      this.isDupRequest = false
      this.isStatusDisabled = false
      this.isStatusWithdrawn = false
      this.isStatusStoppedCredit = false
      this.isStatusStoppedOther = false
      this.isWreqCountLacking = false
      this.isPreqCountMax = false
    }
  }
}
</script>

<style lang="scss" scoped>
.plan_name_wrap {
  min-height: 30px;
  .plan_name {
    margin-right: 6px;
  }
}
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.submit_bx1 {
  margin: 12px auto;
}
.step1do:disabled {
  opacity: 0.6;
}

.btn_add_credit {
  margin: 0px 4px 0px 0px;
  padding: 4px 12px 4px 12px;
  background-color: #00a9ca;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #ffffff;
    filter: alpha(opacity=75);
    -moz-opacity:0.75;
    opacity:0.75;
  }
}

select.count {
  width: 100px;
}
</style>
