// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import router from '@/router'
import store from '@/store'
import * as filters from '@/filter'
import App from '@/components/App'
import VueAnalytics from 'vue-analytics'
import i18n from '@/utils/i18n'
import { library as fontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

import {
  calendarDefaults,
  calendarLangFunc
} from './consts/calendar'
import {
  monthOptions,
  dateOptions,
  hourOptions,
  minuteOptions,
} from './consts/selectOptions'

Vue.config.productionTip = false

// consts
window.VueCalendarDefaults = calendarDefaults
window.VueCalendarLang = calendarLangFunc
window.VueMonthOptions = monthOptions
window.VueDateOptions = dateOptions
window.VueHourOptions = hourOptions
window.VueMinuteOptions = minuteOptions

// register filters
Vue.use(Vue2Filters)
Object.keys(filters).forEach(k => {
  Vue.filter(k, filters[k])
})

fontAwesomeLibrary.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// register global components
Vue.component('my-calendar', require('@/components/lib/MyCalendar').default)
Vue.component('pager', require('@/components/lib/Pager').default)
Vue.component('modal', require('@/components/lib/Modal').default)
Vue.component('loading', require('@/components/lib/Loading').default)
Vue.component('header-bar', require('@/components/lib/HeaderBar').default)
Vue.component('wash-request-notice-messages', require('@/components/lib/WashRequestNoticeMessages').default)
Vue.component('wash-request-week-notice-messages', require('@/components/lib/WashRequestWeekNoticeMessages').default)
Vue.component('wash-request-onetime-notice-messages', require('@/components/lib/WashRequestOnetimeNoticeMessages').default)
Vue.component('edit-plan-notice-messages', require('@/components/lib/EditPlanNoticeMessages').default)
Vue.component('withdrawal-notice-messages', require('@/components/lib/WithdrawalNoticeMessages').default)
Vue.component('maintenance-messages', require('@/components/lib/MaintenanceMessages').default)
Vue.component('no-login-header', require('@/components/lib/NoLoginHeader').default)
Vue.component('no-login-footer', require('@/components/lib/NoLoginFooter').default)

// // Google Analytics
// Vue.use(VueAnalytics, {
//   id: 'UA-111388440-1',
//   router
// })

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  components: { App },
  render: h => h(App),
}).$mount('#app')
