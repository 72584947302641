<template>
  <div>
    <no-login-header />
    <h1 class="pagetitle">{{ $t('register.title') }}</h1>
    <InputStepView
      v-if="isInputStep"
      @to-confirm-step="toConfirmStep"
    />
    <ConfirmStepView
      v-else-if="isConfirmStep"
      @back-to-input-step="backToInputStep"
    />
    <no-login-footer />

    <SameTimeDeliveryNoteModal
      v-if="state.showSameTimeDeliveryNoteModal"
      @dismiss="state.showSameTimeDeliveryNoteModal = false"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed } from 'vue'
import areaInfoApi from '@/api/areaInfo'
import { provideMaster } from '@/composables/useMaster'
import { provideUserState } from '@/components/lib/user/composables/useUserState'
import { provideUserErrorState } from '@/components/lib/user/composables/useUserErrorState'
import { provideCommonState } from '@/components/Register/composables/useCommonState'
import InputStepView from '@/components/Register/components/InputStepView.vue'
import ConfirmStepView from '@/components/Register/components/ConfirmStepView.vue'
import SameTimeDeliveryNoteModal from '@/components/lib/SameTimeDeliveryNoteModal.vue'

export default defineComponent({
  name: 'register',
  setup() {
    // TODO プランに関係するものはusePlanStateを作って切り出したい
    const state = provideCommonState()
    provideUserState()
    provideUserErrorState()
    const { _masterPromise: masterPromise } = provideMaster()

    const isInputStep = computed(() => {
      return state.currentStep === 1
    })
    const isConfirmStep = computed(() => {
      return state.currentStep === 2
    })

    function toConfirmStep() {
      state.currentStep = 2
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }
    function backToInputStep() {
      state.currentStep = 1
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }

    onMounted(async () => {
      // TODO commonStateからmasterData周りのものを剥がしていきたい
      const [masterData, areaInfoResponse] = await Promise.all([
        masterPromise,
        areaInfoApi.index(),
      ])

      state.lovs = masterData.lovs
      state.plans = masterData.plans
      state.planMap = masterData.planMap
      state.lovsMap = masterData.lovsMap
      state.other = masterData.other

      state.sizeDisplay = state.lovs.size.reduce((acc, e) => {
        e = { ...e }
        if (e.key === '1') {
          e.display = e.val + "（35L）"
        } else if(e.key === '2') {
          e.display = e.val + "（80L）"
        } else if(e.key === '3') {
          e.display = e.val + "（140L）"
        } else if(e.key === '4') {
          e.display = e.val + "（250L）"
        }
        acc[e.key] = e;

        return acc
      }, {})

      if (state.lovs.plan_part) {
        state.planParts1 = state.lovs.plan_part.filter(p => {
          // お試しプランは選択不可
          return p['key'] !== 't0'
        })
      }

      state.areaInfos = areaInfoResponse.data
    })

    return {
      state,
      // computed
      isInputStep,
      isConfirmStep,
      // methods
      toConfirmStep,
      backToInputStep,
    }
  },
  components: {
    InputStepView,
    ConfirmStepView,
    SameTimeDeliveryNoteModal,
  },
})
</script>

<style lang="scss" scoped>
</style>
