<template>
<div>
  <div class="contents_855">
    <header-bar active=""></header-bar>
    <h1 class="title2">{{ $t('contact.title') }}</h1>
    <div class="contents_box">
      <h3 class="form_notes">{{ $t('contact.faq') }}</h3>
    </div>
    <div class="contents_box">
      <ul class="list mg25">
        <p class="item3 left">{{ $t('contact.questions.creditCard.question') }}</p>
        <li><p>{{ $t('contact.questions.creditCard.answer') }}</p></li>
        <br>
        <p class="item3 left">{{ $t('contact.questions.changePlan.question') }}</p>
        <li><p>{{ $t('contact.questions.changePlan.answer') }}</p></li>
        <br>
        <p class="item3 left">{{ $t('contact.questions.additionalBag.question') }}</p>
        <li><p>{{ $t('contact.questions.additionalBag.answer1') }}</p></li>
        <p>{{ $t('contact.questions.additionalBag.answer2') }}</p>
        <p>{{ $t('contact.questions.additionalBag.pricing') }}</p>
      </ul>
    </div>
    <p class="submit_bx1">
      <a href="https://awesomewash.zendesk.com/hc/ja/requests/new" target="_blank" class="submit1 bg_green">
        {{ $t('contact.contactButton') }}
      </a>
    </p>
  </div>
</div>
</template>

<script>
import contactApi from '@/api/contact'
import HeaderBar from './lib/HeaderBar.vue'

export default {
  components: { HeaderBar },
  name: 'contact',
  data() {
    return {
      currentStep: 1,
      maxStep: 3,
      isStep1: false,
      isStep2: false,
      isStep3: false,

      params: {
        email: '',
        contact: '',
      },

      serverErrors: [],
    }
  },
  computed: {
    isInputOK() {
      return this.params.email && this.params.contact
    }
  },
  mounted() {
    this.$store.dispatch('user/getMe')
      .then(me => {
        this.params.email = me.email
      })
  },
  methods: {
    nextStep() {
      if (!this.checkCurrentStep()) { return }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    checkStep1() {
      let result = true

      if (!this.params.email) {
        result = false
      }
      if (!this.params.contact) {
        result = false
      }

      return result
    },
    checkStep2() {
      return true
    },
    sendContact() {
      this.serverErrors = []

      contactApi.send(this.params)
        .then(({ data }) => {
          this.nextStep()
        })
        .catch(e => {
          const errRes = e.response.data.errors;
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors;
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  filter: alpha(opacity=5);
  -moz-opacity:0.5;
  opacity:0.5;
}
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.left {
  transform: translate(-17px);
}
</style>
