import { getCurrentInstance, inject, provide, reactive } from 'vue'

const injectionKey = 'user.useUserState'

function getInitialUserState() {
  return {
    name1: '',
    name2: '',
    kana1: '',
    kana2: '',
    is_company: false,
    company_name: '',
    company_name_kana: '',
    company_hq_phone_number: '',
    company_ceo_name1: '',
    company_ceo_name2: '',
    company_ceo_name_kana1: '',
    company_ceo_name_kana2: '',
    bill_addr_zipcode: '',
    bill_addr_detail1: '',
    bill_addr_detail2: '',
    bill_addr_detail3: '',
    send_addr_name: '',
    send_addr_zipcode: '',
    send_addr_detail1: '',
    send_addr_detail2: '',
    send_addr_detail3: '',
    send_addr_detail3_room_number: '',
    phone_number: '',
    email: '',
    email_confirm: '',
    email2: '',
    email2_confirm: '',
    password: '',
    password_confirm: '',
    bikou1: '',
    payment_method_id: '1',
    user_plan: {},
    current_lang: 'ja',
    source_venue_code: '',
    venue: {},
  }
}

function fillUserStateWithMe(userState, me) {
  userState.name1 = me.name1
  userState.name2 = me.name2
  userState.kana1 = me.kana1
  userState.kana2 = me.kana2
  userState.is_company = me.is_company
  userState.company_name = me.company_name
  userState.company_name_kana = me.company_name_kana
  userState.company_hq_phone_number = me.company_hq_phone_number
  userState.company_ceo_name1 = me.company_ceo_name1
  userState.company_ceo_name2 = me.company_ceo_name2
  userState.company_ceo_name_kana1 = me.company_ceo_name_kana1
  userState.company_ceo_name_kana2 = me.company_ceo_name_kana2
  userState.bill_addr_zipcode = me.bill_addr_zipcode
  userState.bill_addr_detail1 = me.bill_addr_detail1
  userState.bill_addr_detail2 = me.bill_addr_detail2
  userState.bill_addr_detail3 = me.bill_addr_detail3
  userState.send_addr_name = me.send_addr_name
  userState.send_addr_zipcode = me.send_addr_zipcode
  userState.send_addr_detail1 = me.send_addr_detail1
  userState.send_addr_detail2 = me.send_addr_detail2
  userState.send_addr_detail3 = me.send_addr_detail3
  userState.phone_number = me.phone_number
  userState.email = me.email
  userState.email_confirm = me.email
  userState.email2 = me.email2
  userState.email2_confirm = me.email2
  userState.bikou1 = me.bikou1
  userState.payment_method_id = me.payment_method_id
  userState.user_plan = me.user_plan
  userState.current_lang = me.current_lang
  userState.source_venue_code = me.source_venue_code
}

export const provideUserState = () => {
  const userState = reactive(getInitialUserState())
  provide(injectionKey, userState)
  return userState
}

export const provideFilledUserState = () => {
  const userState = reactive(getInitialUserState())
  provide(injectionKey, userState)

  const vue = getCurrentInstance().proxy
  vue.$store.dispatch('user/getMe')
    .then(me => {
      fillUserStateWithMe(userState, me)
    })

  return userState
}

export const useUserState = () => {
  const userState = inject(injectionKey)
  if (!userState) {
    throw new Error('useUserState() is called without provider.')
  }

  const vue = getCurrentInstance().proxy
  const forceRefresh = async () => {
    const me = await vue.$store.dispatch('user/getMe', { forceRefresh: true })
    fillUserStateWithMe(userState, me)
  }

  return { userState, forceRefresh }
}
