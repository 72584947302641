<template>
<div class="contents_855">
  <header-bar active="wash-request"></header-bar>

  <div class="form_title">
    <h1 class="title2">{{ $t('washRequest.spotOrder') }}</h1>
  </div>
  <div class="contents_box">
    <p class="mypage_notes mg40 mg_bt40 font_orange" v-html="$t('washRequest.requestComplete')">
    </p>
    <p class="mypage_notes mg40">
      {{ $t('washRequest.emailConfirmation') }}<br>
      <span v-html="$t('washRequest.checkDeliveryNotes')"></span>
    </p>
    <p class="submit_bx1">
      <router-link :to="{name:'wash-request-currents'}" class="glnavi_btn_on">
        {{ $t('washRequest.checkDetails') }}
      </router-link>
    </p>
    <p class="mypage_notes mg40">
      <a href="https://awesome-wash.com/friend/" target="_blank">
        <picture>
          <source media="(min-width: 641px)" srcset="https://awesome-wash.com/wp-content/themes/aw_themes/images/bn_friand.jpg">
          <source media="(max-width: 640px)" srcset="https://awesome-wash.com/wp-content/themes/aw_themes/images/bn_friand_sp.jpg">
          <img src="https://awesome-wash.com/wp-content/themes/aw_themes/images/bn_friand.jpg" :alt="$t('washRequest.friendBannerAlt')" style="width:100%">
        </picture>
      </a>
    </p>
  </div>
</div>
</template>

<script>
export default {
  name: 'onetime-wash-request-complete',
}
</script>

<style lang="scss" scoped>
.submit_bx1 {
  margin: 12px auto;
}
.form_title{
    display:flex;
}
.title2{
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 0;
  text-align:center;
  font-size: 16px;
  font-weight: normal;
  clear:both;
}
</style>
