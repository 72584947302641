<template>
  <tr>
    <th><p class="item3">{{ $t('password.label') }}<span class="font_orange">*</span></p></th>
    <td>
      <p>
        <input type="password" size="20" class="form_basic" v-model="userState.password" :class="{error_outline: userErrorState.errors.password}">
        <div class="font_orange">{{ $t('password.alphanumericNote') }}</div>
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors.password_required">
          ▲ {{ $t('required') }}
        </span>
      </p>
      <p>
        <span class="iferror" v-show="userErrorState.errors.password_invalid">
          ▲ {{ $t('password.formatError') }}
        </span>
      </p>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'
import { useUserErrorState } from '@/components/lib/user/composables/useUserErrorState'

export default defineComponent({
  setup() {
    const { userState } = useUserState()
    const { userErrorState } = useUserErrorState()

    return {
      userState,
      userErrorState,
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
