import axios from 'axios'
import * as utils from './api-utils'
import { localizeLovs, localizePlanMaster } from '@/utils/i18n'

export default {
  getAll() {
    return axios.get(utils.getApiUrl('/masters'))
      .then(({ data }) => {
        localizeLovs(data.lovs)
        const lovsMap = {}
        Object.keys(data.lovs).forEach(k => {
          const arr = data.lovs[k]
          lovsMap[k] = arr.reduce((acc, e) => {
            acc[e.key] = e; return acc
          }, {})
        })
        data.lovsMap = lovsMap

        localizePlanMaster(data.plans)
        data.planMap = data.plans.reduce((acc, e) => {
          acc[e.plan_id] = e; return acc
        }, {})

        return data
      })
  },
  searchZipcode(zipcode) {
    return axios.get(
      utils.getApiUrl('/zipcode'),
      {params: {zc: zipcode}}
    ).then(({ data }) => {
      // 郵便番号データの仕様により、住所の後ろ側に「（次のビルを除く）」という文字列が付いている場合があるが邪魔なので除く
      const removeStr = '（次のビルを除く）'
      if (data.address2) {
        data.address2 = data.address2.replace(removeStr, '')
      }
      if (data.address3) {
        data.address3 = data.address3.replace(removeStr, '')
      }
      return data
    })
  },
  maintenanceIndex() {
    return axios.get(utils.getApiUrl('/maintenance'))
  },
  maintenanceStart(data) {
    return axios.post(utils.getApiUrl('/admin/maintenance/start'), data)
  },
  maintenanceEnd() {
    return axios.post(utils.getApiUrl('/admin/maintenance/end'))
  },
}
