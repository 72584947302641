<template>
  <tr>
    <th><p class="item2">{{ $t('profile.remarks') }}</p></th>
    <td>
      <p>
        <textarea type="text" size="20" class="form_basic" v-model="userState.bikou1"></textarea>
      </p>
    </td>
  </tr>
</template>

<script>
import { defineComponent } from 'vue'
import { useUserState } from '@/components/lib/user/composables/useUserState'

export default defineComponent({
  setup() {
    const { userState } = useUserState()

    return {
      userState,
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
