<template>
<ul class="glnavi tab_none sp_none">
  <li v-for="item in links">
    <a v-if="item.name == 'wash-request'" href='/wash-request' class="glnavi_btn" key=""
        :class="{glnavi_btn_on: active === item.name}">
      {{ $t('deliveryRequest') }}
    </a>
    <router-link v-else :to="{name:item.name}"
        class="glnavi_btn"
        :class="{glnavi_btn_on: active === item.name}">
      {{item.label}}
    </router-link>
  </li>
</ul>
</template>

<script>
import vue from 'vue'
import userapi from '@/api/user'

export default {
  name: 'header-bar',
  props: {
    active: {
      type: String,
      default: 'wash-request'
    },
  },
  data() {
    return {
      links: [
        {
          name: 'wash-request',
          label: this.$t('deliveryRequest'),
        },
        {
          name: 'wash-request-currents',
          label: this.$t('changeOrCancel'),
        },
        {
          name: 'wash-history',
          label: this.$t('usageHistory'),
        },
        {
          name: 'profile',
          label: this.$t('customerInfo'),
        },
        {
          name: 'plan',
          label: this.$t('changePlan'),
        },
      ]
    }
  },
}
</script>
